.review-section {
  > table,
  > .table {
    tr td:first-of-type {
      font-weight: bold;
    }
    td {
      line-height: 1.5;
    }
  }

  h3 {
    @include clearfix;

    span {
      float: right;
      font-size: 13px;
      a {
        color: $brand-green;
      }
    }
  }
}
