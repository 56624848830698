.simple-progress-tracker {
  display: block;
  position: relative;
  text-indent: 0;
  margin-left: 0;
  margin-bottom: 40px;
  text-align: center;
  z-index: 1;

  .step {
    position: relative;
    list-style-type: none;
    text-align: center;
    display: inline-block;
    padding: 0 20px;
    vertical-align: top;

    &::before {
      content: "";
      display: block;
      background-color: $grey-light;
      width: 120%;
      height: 2px;
      position: absolute;
      top: 11px;
      left: 0;
      z-index: -1;
    }

    &:first-of-type {
      &::before {
        left: 30px;
      }
    }
    &:last-of-type {
      &::before {
        left: auto;
        right: 30px;
      }
    }

    .step-marker {
      display: block;
      width: 16px;
      height: 16px;
      margin: auto;
      margin-top: 4px;
      border-radius: 50em;
      border: 3px solid $grey-light;
      background-color: $white;
    }

    .step-label {
      display: block;
      color: $grey-medium;
    }

    &.major {
      .step-marker {
        width: 24px;
        height: 24px;
        border-width: 4px;
        margin-top: 0;
      }
    }

    &.minor {
      .step-label {
        display: none;
      }
    }
    
    &.active {
      .step-marker {
        border-color: $brand-blue;
      }
      .step-label {
        color: $brand-blue;
        font-weight: bold;
      }
    }
  }
}
