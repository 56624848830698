.btn {
  @include brand-font;
  min-width: 130px;

  &.btn-tw-tertiary {
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  &-tw-tiny {
    font-size: 12px;
    padding: 5px 10px;
    min-width: auto;
    line-height: normal;
  }

  &-tw-lg {
    padding: 10px 30px;
  }
  
  &-tw-muted {
    background-color: $white;
    border-color: $grey-medium;
    border-width: 2px;
    border-radius: 200px;
    color: $grey-medium;

    &:hover,
    &:active,
    &:focus {
      background-color: $grey-medium;
      color: $white;
    }
  }

  &.addressSupply_pclink {
    width: auto !important;
  }
}
