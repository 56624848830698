.summary-panel {
  @include clearfix;
  background-color: $blue-light;
  border-radius: $radius;
  padding: $spacing-md;
  padding-bottom: $spacing-xl;

  &__count {
    @include thames-font($size: 34px);
    margin-top: $spacing-md;
    background-color: $white;
    border-radius: $radius-lg;
    padding: $spacing-lg $spacing-md;
    text-align: center;
    line-height: 1;
    
    span {
      @include body-font($size: 14px);
      display: block;
      color: $grey-medium;
      margin-top: $spacing;
  }
    
    .connection-summary & {
      @include thames-font($size: 60px);
      float: left;
      width: 200px;
      margin-left: $spacing-md;
      padding: $spacing-md $spacing-md;
    }
  }

  &__breakdown {
    margin-top: $spacing-md;
    
    .connection-summary & {
      @include thames-font($color: $black);
      float: left;
      width: calc(100% - 200px - $spacing-xl);
      margin-left: $spacing-md;
    }
    table {
      tr {
        td {
          width: 50%;
          &:last-of-type {
            text-align: right;
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 15px;
        &:before {
          display: none;
        }
      }
    }
  }
  
  hr {
    border-color: $grey-medium;
  }
}

.fees-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  
  &-container {
    border: 1px solid $grey-light;
    border-radius: $radius;

  }

  &-wrapper {
    margin-top: 50px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  &-description {
    padding: 0 15px;
  }

  tr {
    th,
    td {
      &:first-of-type {
        text-align: left;
        width: 35%;
      }
    }

    &:last-of-type td {
      border-bottom: 0;
    }

    &.fees-table__subtotal-row {
      background-color: $grey-extralight;

      td {
        border-top: 1px solid $grey-light;
  
        &:last-of-type {
          font-weight: bold;
        }
      }
    }
  
    &.fees-table__deduction-row {
      td {
        color: $red;
      }
    }
  }

  th,
  td {
    text-align: right;
    padding: 10px 15px;
    border-collapse: collapse;
    border-bottom: 1px solid $grey-light;
  }

  th {
    border-bottom: 3px solid $grey-light;

    span {
      display: block;
      font-weight: normal;
    }
  }
}

.fees-summary {
  &-wrapper {
    @include clearfix;
    margin-top: 30px;
    border-top: 4px solid $grey-light;
    border-bottom: 4px solid $grey-light;
  }

  float: right;
  text-align: right;
  border-collapse: collapse;
  font-size: 16px;
  width: auto;

  tr {
    td {
      padding: 15px 15px;
      border-bottom: 1px solid $grey-light;
      &:last-of-type {
        width: 130px;
      }
    }
    &.focus-row {
      font-size: 21px;
      font-weight: bold;
    }
    &.deductions-row {
      color: $red;
    }
  }
}

.inline__hint {
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  .fa {
    background-color: $brand-blue;
    border-radius: 50%;
    color: $white;
    height: $spacing-md;
    width: $spacing-md;
    text-align: center;
    padding: 2px;
    margin-left: 5px;
  }
}

.file {
  &-reset {
    float: right;
    margin-right: 15px;
    margin-top: 7px;
  }
  &-size {
    float: right;
  }
}

.convert-action {
  @include panel($border: $brand-blue, $padding: $spacing);
  text-align: center;

  .btn {
    margin-top: $spacing-md;
    margin-bottom: $spacing;
  }
}

.convert-to-panel {
  @include panel;
  margin-bottom: $spacing-md;

  .panel-header {
    .form-group {
      margin: 0;
      padding: 0;
    }
    label {
      @include brand-font;
      font-size: 21px;
      margin-top: 3px;
      margin-right: 10px;
    }
    .btn {
      margin: 0;
    }
  }
}


ol.your-next-steps {
  text-indent: 0;

  li {
    list-style-position: outside;
    @include thames-font;
    font-size: 21px;
    margin-bottom: $spacing-md;
    padding-left: $spacing;
    
    p, label, input {
      @include body-font;
    }
    label {
      font-weight: bold;
    }
    .form-group {
      padding: 0;
    }
  }
}


.gis-map {
  .form & {

    padding-bottom: $spacing;
  }

  iframe {
    margin-bottom: $spacing;
  }
}