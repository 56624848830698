body {
  &.loading {
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }

    &:after {
      position: fixed;
      top: 50%;
      left: 50%;
      content: " ";
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 5px solid $brand-blue;
      border-color: $brand-blue transparent $brand-blue transparent;
      z-index: 101;
      animation: loading-spinner 0.7s linear infinite;
    }
  }
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
a {
  color: $brand-green;
  
  .alert & {
    color: black;
    text-decoration: underline;
  }
}

p {
  > img {
    display: inline-block;
    max-width: 100%;
    min-width: auto;
    width: auto;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    vertical-align: top;
  }
}
