.form {
  border: 0;
  padding: 0;

  section {
    border-bottom: 1px solid $grey-light;
    margin-bottom: $spacing-xl;
    padding-bottom: $spacing-xl;
    
    &.no-border {
      border: 0;
      margin-bottom: $spacing-lg;
      padding-bottom: $spacing-lg;
    }

    p {
      padding: 0 0 10px 0;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .journey-selection {
    &.has-focus {
      background: none !important;
    }
    .fieldset {
      width: 100%;
    }
    .checkbox {
      margin-bottom: 25px;
    }
    &__heading {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 5px;
    }

    &__description {
      font-weight: normal;
      font-size: 13px;
      padding: 0;
      margin: 0;
      margin-left: 36px;
    }

    .choice-touts {
      justify-content: space-around;

      .choice-tout {
        width: 30%;

        .innerspace {
          flex-direction: column;
          align-content: flex-start;
          padding: 15px;

          &::after {
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
          }
          .journey-selection__heading {
            min-height: 80px;
            margin-top: 10px;
          }
          .journey-selection__description {
            min-height: 100px;
            font-size: 13px;
            color: $grey-medium;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.form-control {
  &-short {
    min-width:200px;
    width: auto;
  }
  &-veryshort {
    max-width:100px;
    width: auto;
  }
  &[readonly], fieldset[readonly] &,
  &[disabled], fieldset[disabled] & {
    cursor: not-allowed;
  }
}

.form-group {
  padding: 10px;

  &.is-nested {
    margin-left: 32px;
  }

  .control-label {
    @include breakpoint($break-sm) {
      width: 260px;
      padding-right: 60px;
    }
    &.checkbox {
      @include breakpoint($break-sm) {
        width: auto;
      }
    }
  }
  .fieldset {
    @include breakpoint($break-sm) {
      width: calc(100% - 260px);
    }
  }
  .hint {
    .hint-content {
      margin-left: 0;
      width: 100%;
    }
    .fa-info {
      left: 230px;
    }
  }
}

.radio-button-other-field {
  display: block;
  width: 170px;
  margin-top: 10px;
  margin-left: 30px;
  @include breakpoint($break-lg) {
    display: inline-block;
    width: 170px;
    margin-top: 0;
    margin-left: 10px;
  }
}
.form-step-controls {
  display: flex;
  justify-content: space-between;
  padding-top: $spacing-xl;
  margin-top: $spacing-xl;
  border-top: 1px solid $grey-light;

  .step-exit {
    margin-right: 20px;
  }
}

.form-autocomplete {
  display: none;
  position: absolute;
  top: 16px;
  z-index: 10;
  height: 250px;
  width: 400px;
  overflow: scroll;
  background-color: $white;
  border: 1px solid $grey-light;
  border-radius: $radius;
  padding: 0 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);

  &.visible {
    display: block;
  }
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  & + span {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: 2px;
  }
}