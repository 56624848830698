// colors
$brand-blue: #009fdf;
$brand-green: #7fba00;
$brand-green-darker: #4d8504;

$white: #ffffff;
$black: #000000;
$grey-extralight: #F5F5F2;
$grey-light: #d5e2de;
$grey-medium: #71857E;
$red-light: #fcdfd9;
$blue-light: #d9f1fa;
$red: #d0240b;
$validation-red: #a70808;
$validation-green: #33691E;

$spacing: 10px;
$spacing-md: 20px;
$spacing-lg: 30px;
$spacing-xl: 40px;

$radius: 4px;
$radius-lg: 8px;

$fa-font-path: "../fonts/";

// MIXINS

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin brand-font() {
  font-family: "Gotham Rounded","Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 700;
}

@mixin thames-font($size: 16px, $color: $brand-blue) {
    font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: 500;
    line-height: 1.2em;
    font-size: $size;
    color: $color;
}
@mixin body-font($size: 14px, $color: $black) {
    font-family: Arial, "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: 300;
    font-size: $size;
    color: $color;
}

/* Custom, iPhone Retina */
$break-xxs: "min-width : 320px";

/* Extra Small Devices, Phones */
$break-xs: "min-width : 480px";

/* Small Devices, Tablets */
$break-sm: "min-width : 740px";

/* Medium Devices, Desktops */
$break-md: "min-width : 1020px";

/* Large Devices, Wide Screens */
$break-lg: "min-width : 1260px";

@mixin breakpoint($size) {
  @media only screen and ($size) {
    @content;
  }
}


@mixin panel($border: $grey-light, $padding: $spacing-md) {
  border: 2px solid $border;
  border-radius: $radius;
  padding: $padding;
}

