.accordions {
    .panel {
      padding:0;

      &-body {
        padding: 0 $spacing $spacing-md $spacing;
      }

      &-title {
        > span {
          padding: $spacing-md $spacing;
        }
      }
    }
  }
  