.field-validation {
    border-radius: $radius;
  
    span {
      display: block;
      padding: 7px 10px;
    }
  
    &-error {
      margin-top: 10px;
      color: $validation-red;
      // background-color: #fce3de;
    }
  
    &-valid {
      margin-top: 10px;
      color: $validation-green;
      // background-color: #DCEDC8;
    }
  }
  