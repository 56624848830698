.header {
  border-bottom: 2px solid $grey-light;
  text-align: left;

  .logo {
    @include thames-font($size: 22px, $color: $black);
    display: inline-block;
    position: relative;
    background: transparent url("../assets/thames-water-logo.svg") no-repeat 0 0;
    height: 90px;
    margin: 20px 0;
    padding-left: 110px;
    padding-top: 30px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .logo img {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
