table.water-fittings-table {
  tr {
    &:nth-of-type(2n) {
      background-color: $grey-extralight;
    }

    td {
      vertical-align: middle;

      &.form-group {
        @include clearfix;
        width: 80%;

        .inline__hint {
          float: right;
          margin-right: 10px;
        }
      }
      &.fieldset {
        width: auto;
      }
    }
  }

  .control-label {
    width: auto;
    float: none;
    padding: 0;
    font-weight: normal;
  }

  .form-control-inline {
    float: none;
    margin-right: 0;
    width: 100%;
  }
}
