.main {
  header {
    margin-bottom: 0;

    h1 {
      border: 0;
      padding-bottom: 0;
    }
  }

  & > header {
    margin-bottom: $spacing-md;
  }

  section header {
    margin-bottom: $spacing-md;
  }
}