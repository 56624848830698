.panel {
  &-default {
    border: 2px solid $grey-light;
    padding: 20px;

    h1,
    h2,
    h3 {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}