// FontAwsome icon fixes
.fa-info {
  font-size: 13px;
  line-height: 1.3;
}
.checkbox input[type="checkbox"]:checked ~ span:before {
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-check);
  line-height: 1.5;
}

.alert-success::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1.2;
  content: fa-content($fa-var-check);
}

.alert-info::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1.2;
  content: fa-content($fa-var-info);
}

.alert-warning::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1.2;
  content: fa-content($fa-var-exclamation);
}

.alert-danger::before {
  content: '';
  background-image: url('/areas/twforms/static/images/icon/warning.svg');
  background-color: transparent;
  border-radius: 0;
}

.download::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1;
  content: fa-content($fa-var-arrow-alt-circle-down);
}
.download[href$=".pdf"]::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1;
  content: fa-content($fa-var-file-pdf);
}
.primary-link:after {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1;
  content: fa-content($fa-var-chevron-circle-right);
}

ul.bullet li::before,
ul:not(.nav-level):not(.tout-list):not(.fa-ul):not(.level):not(.pagination):not(.pager):not(.option-list):not(.downloads):not(.form-autocomplete):not(.gallery-list):not(.btn-container):not(.plumber-articles):not(.chosen-results) li::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1;
  content: fa-content($fa-var-circle);
  
  left: 10px;
  margin-right: 15px;
}

.global-nav a::after {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1;
  content: fa-content($fa-var-chevron-circle-right);
}
.accordions .panel-title.collapsed>span::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1.3;
  content: fa-content($fa-var-plus);
}
.accordions .panel-title>span::before {
  @extend %fa-icon;
  @extend .fas;
  line-height: 1.3;
  content: fa-content($fa-var-minus);
}