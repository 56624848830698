﻿@charset "UTF-8";
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: 0;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: 0;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.flickity-prev-next-button:hover {
  background: white;
}
.flickity-prev-next-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #09f;
}
.flickity-prev-next-button:active {
  opacity: 0.6;
}
.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}
.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
.flickity-prev-next-button .arrow {
  fill: #333;
}
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-rtl .flickity-page-dots {
  direction: rtl;
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.bg-tw-blue {
  background-color: #009fdf;
}
.bg-tw-green {
  background-color: #7fba00;
}
.bg-tw-green-tint {
  background-color: #ecf5d9;
}
.bg-tw-light-blue {
  background-color: #4cc2f1;
}
.bg-tw-light-blue {
  background-color: #4cc2f1;
}
.bg-tw-white {
  background-color: #fff;
}
.bg-tw-transparent {
  background-color: transparent;
}
.c-tw-blue {
  color: #009fdf;
}
.c-tw-green {
  color: #7fba00;
}
.c-tw-orange {
  color: #f07d28;
}
.c-tw-red {
  color: $red;
}
.c-tw-white {
  color: #fff;
}
.c-tw-black {
  color: #000;
}
.c-tw-transparent {
  color: transparent;
}
.debug * {
  outline: 1px solid red;
}

.tw {
  display: inline-block;
  font: normal normal normal 14px / 1 ThamesWater;
  font-size: inherit;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tw-account::before {
  content: "";
}
.tw-alert::before {
  content: "";
}
.tw-billdate::before {
  content: "";
}
.tw-cog::before {
  content: "";
}
.tw-document::before {
  content: "";
}
.tw-done::before {
  content: "";
}
.tw-email::before {
  content: "";
}
.tw-laptop::before {
  content: "";
}
.tw-mail::before {
  content: "";
}
.tw-password::before {
  content: "";
}
.tw-search::before {
  content: "";
}
.tw-telephone::before {
  content: "";
}
.tw-water::before {
  content: "";
}
.tw-eps::before {
  content: "";
}
.tw-gif::before {
  content: "";
}
.tw-jpg::before {
  content: "";
}
.tw-ms-excel::before {
  content: "";
}
.tw-ms-powerpoint::before {
  content: "";
}
.tw-ms-word::before {
  content: "";
}
.tw-pdf::before {
  content: "";
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: Arial, 'Helvetica Neue', 'Helvetica', sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #000;
  background-color: #fff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0;
  border-top: 1px solid #eee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role="button"] {
  cursor: pointer;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none;
}
pre {
  display: block;
  padding: 9px;
  margin: 0 0 9.5px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

table {
  background-color: transparent;
}
caption {
  padding-top: 14px;
  padding-bottom: 14px;
  color: #777;
  text-align: left;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 19px;
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 14px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #009fdf;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #009fdf;
}
.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #009fdf;
}
.table .table {
  background-color: #fff;
}
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #009fdf;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #009fdf;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #d9f1fa;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}
.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #7fba00;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #6ea100;
}
.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #d62906;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #bd2405;
}
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 739px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 14.25px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #009fdf;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 19px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
}
.form-control {
  display: block;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dae6e2;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #009fdf;
  outline: 0;
}
.form-control::-moz-placeholder {
  opacity: 1;
}
.form-control:-ms-input-placeholder {
}
.form-control::-webkit-input-placeholder {
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    line-height: 33px;
  }
  input[type="date"].input-sm,
  .input-group-sm > input[type="date"].form-control,
  .input-group-sm > input[type="date"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="date"].btn,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm > input[type="time"].form-control,
  .input-group-sm > input[type="time"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="time"].btn,
  .input-group-sm input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm > input[type="datetime-local"].form-control,
  .input-group-sm > input[type="datetime-local"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="datetime-local"].btn,
  .input-group-sm input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm > input[type="month"].form-control,
  .input-group-sm > input[type="month"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="month"].btn,
  .input-group-sm input[type="month"] {
    line-height: 30px;
  }
  input[type="date"].input-lg,
  .input-group-lg > input[type="date"].form-control,
  .input-group-lg > input[type="date"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="date"].btn,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg > input[type="time"].form-control,
  .input-group-lg > input[type="time"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="time"].btn,
  .input-group-lg input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg > input[type="datetime-local"].form-control,
  .input-group-lg > input[type="datetime-local"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="datetime-local"].btn,
  .input-group-lg input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg > input[type="month"].form-control,
  .input-group-lg > input[type="month"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="month"].btn,
  .input-group-lg input[type="month"] {
    line-height: 46px;
  }
}
.form-group {
  margin-bottom: 15px;
}
.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio label,
.checkbox label {
  min-height: 19px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
input[type="radio"][disabled],
input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}
.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}
.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}
.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 33px;
}
.form-control-static.input-lg,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.form-control-static.input-sm,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}
.input-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
select.input-sm,
.input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}
textarea.input-sm,
.input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select[multiple].form-control,
.input-group-sm > select[multiple].input-group-addon,
.input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto;
}
.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  min-height: 31px;
}
.input-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
}
select.input-lg,
.input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 46px;
  line-height: 46px;
}
textarea.input-lg,
.input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select[multiple].form-control,
.input-group-lg > select[multiple].input-group-addon,
.input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto;
}
.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
}
.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  min-height: 37px;
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 41.25px;
}
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  pointer-events: none;
}
.input-lg + .form-control-feedback,
.input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}
.input-sm + .form-control-feedback,
.input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #7fba00;
}
.has-success .form-control {
  border-color: #7fba00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .input-group-addon {
  color: #7fba00;
  border-color: #7fba00;
  background-color: #7fba00;
}
.has-success .form-control-feedback {
  color: #7fba00;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b;
}
.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3;
}
.has-warning .form-control-feedback {
  color: #8a6d3b;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #d62906;
}
.has-error .form-control {
  border-color: #d62906;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .input-group-addon {
  color: #d62906;
  border-color: #d62906;
  background-color: #d62906;
}
.has-error .form-control-feedback {
  color: #d62906;
}
.has-feedback label ~ .form-control-feedback {
  top: 24px;
}
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #404040;
}
@media (min-width: 740px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 26px;
}
.form-horizontal .form-group {
  margin-left: -10px;
  margin-right: -10px;
}
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: " ";
  display: table;
}
.form-horizontal .form-group:after {
  clear: both;
}
@media (min-width: 740px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 10px;
}
@media (min-width: 740px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.3333px;
  }
}
@media (min-width: 740px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
  }
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  background-image: none;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-image: none;
}
.btn-success {
  color: #fff;
  background-color: #7fba00;
  border-color: #6ea100;
}
.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  background-image: none;
}
.btn-info {
  color: #fff;
  background-color: #009fdf;
  border-color: #008dc6;
}
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  background-image: none;
}
.btn-warning {
  color: #fff;
  background-color: #e8b600;
  border-color: #cfa200;
}
.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  background-image: none;
}
.btn-danger {
  color: #fff;
  background-color: #d62906;
  border-color: #bd2405;
}
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  background-image: none;
}
.btn-link {
  color: #337ab7;
  font-weight: normal;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 8.5px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  color: #777;
  white-space: nowrap;
}
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
@media (min-width: 740px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  margin-left: -5px;
}
.btn-toolbar:before,
.btn-toolbar:after {
  content: " ";
  display: table;
}
.btn-toolbar:after {
  clear: both;
}
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}
.btn-group > .btn-lg + .dropdown-toggle,
.btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn .caret {
  margin-left: 0;
}
.btn-lg .caret,
.btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
.dropup .btn-lg .caret,
.dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after {
  content: " ";
  display: table;
}
.btn-group-vertical > .btn-group:after {
  clear: both;
}
.btn-group-vertical > .btn-group > .btn {
  float: none;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}
.btn-group-justified > .btn-group .btn {
  width: 100%;
}
.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #dae6e2;
  border-radius: 4px;
}
.input-group-addon.input-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}
.input-group-addon.input-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}
.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  margin-left: -1px;
}
.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 19px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  content: "> ";
  padding: 0 5px;
  color: #71857e;
}
.breadcrumb > .active {
  color: #000;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 19px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: #7fba00;
  background-color: #ecf5d9;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: #7fba00;
  background-color: #ecf5d9;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 2;
  color: #000;
  background-color: #ecf5d9;
  border-color: #337ab7;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  background-color: #ecf5d9;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label-default {
  background-color: #777;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #337ab7;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}
.label-success {
  background-color: #7fba00;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #5c8700;
}
.label-info {
  background-color: #009fdf;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #007bac;
}
.label-warning {
  background-color: #e8b600;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #b58e00;
}
.label-danger {
  background-color: #d62906;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #a41f05;
}
.alert {
  padding: 13px;
  margin-bottom: 19px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
  margin-top: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 33px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  background-color: #ecf5d9;
  border-color: #7fba00;
  color: #000;
}
.alert-success hr {
  border-top-color: #6ea100;
}
.alert-success .alert-link {
  color: black;
}
.alert-info {
  background-color: #d9f1fa;
  border-color: #009fdf;
  color: #000;
}
.alert-info hr {
  border-top-color: #008dc6;
}
.alert-info .alert-link {
  color: black;
}
.alert-warning {
  background-color: #faf4e1;
  border-color: #e8b600;
  color: #000;
}
.alert-warning hr {
  border-top-color: #cfa200;
}
.alert-warning .alert-link {
  color: black;
}
.alert-danger {
  background-color: #fce3de;
  border-color: $red;
  color: #000;
}
.alert-danger hr {
  border-top-color: #db3817;
}
.alert-danger .alert-link {
  color: black;
}
.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
a.list-group-item {
  color: #555;
}
a.list-group-item .list-group-item-heading {
  color: #333;
}
a.list-group-item:hover,
a.list-group-item:focus {
  text-decoration: none;
  color: #555;
  background-color: #f5f5f5;
}
.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  background-color: #eee;
  color: #777;
  cursor: not-allowed;
}
.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}
.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
  color: #777;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #c7ddef;
}
.list-group-item-success {
  color: #7fba00;
  background-color: #7fba00;
}
a.list-group-item-success {
  color: #7fba00;
}
a.list-group-item-success .list-group-item-heading {
  color: inherit;
}
a.list-group-item-success:hover,
a.list-group-item-success:focus {
  color: #7fba00;
  background-color: #6ea100;
}
a.list-group-item-success.active,
a.list-group-item-success.active:hover,
a.list-group-item-success.active:focus {
  color: #fff;
  background-color: #7fba00;
  border-color: #7fba00;
}
.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}
a.list-group-item-info {
  color: #31708f;
}
a.list-group-item-info .list-group-item-heading {
  color: inherit;
}
a.list-group-item-info:hover,
a.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}
a.list-group-item-info.active,
a.list-group-item-info.active:hover,
a.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}
.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}
a.list-group-item-warning {
  color: #8a6d3b;
}
a.list-group-item-warning .list-group-item-heading {
  color: inherit;
}
a.list-group-item-warning:hover,
a.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}
a.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}
.list-group-item-danger {
  color: #d62906;
  background-color: #d62906;
}
a.list-group-item-danger {
  color: #d62906;
}
a.list-group-item-danger .list-group-item-heading {
  color: inherit;
}
a.list-group-item-danger:hover,
a.list-group-item-danger:focus {
  color: #d62906;
  background-color: #bd2405;
}
a.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #d62906;
  border-color: #d62906;
}
.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
.panel {
  margin-bottom: 19px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
  padding: 15px;
}
.panel-body:before,
.panel-body:after {
  content: " ";
  display: table;
}
.panel-body:after {
  clear: both;
}
.panel-heading {
  padding: 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}
.panel-footer {
  padding: 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group + .panel-footer {
  border-top-width: 0;
}
.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}
.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}
.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #009fdf;
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}
.panel-group {
  margin-bottom: 19px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}
.panel-default {
  border-color: #dae6e2;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: none;
  border-color: #dae6e2;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dae6e2;
}
.panel-default > .panel-heading .badge {
  color: none;
  background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dae6e2;
}
.panel-primary {
  border-color: #337ab7;
}
.panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}
.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}
.panel-success {
  border-color: #7fba00;
}
.panel-success > .panel-heading {
  color: #7fba00;
  background-color: #7fba00;
  border-color: #7fba00;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #7fba00;
}
.panel-success > .panel-heading .badge {
  color: #7fba00;
  background-color: #7fba00;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #7fba00;
}
.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}
.panel-warning {
  border-color: #faebcc;
}
.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}
.panel-danger {
  border-color: #d62906;
}
.panel-danger > .panel-heading {
  color: #d62906;
  background-color: #d62906;
  border-color: #d62906;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d62906;
}
.panel-danger > .panel-heading .badge {
  color: #d62906;
  background-color: #d62906;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d62906;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #d9f1fa;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-lg {
  padding: 24px;
  border-radius: 6px;
}
.well-sm {
  padding: 9px;
  border-radius: 3px;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0 / 0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs {
  display: none !important;
}
.visible-sm {
  display: none !important;
}
.visible-md {
  display: none !important;
}
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 739px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 739px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 739px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 739px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 740px) and (max-width: 1019px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 740px) and (max-width: 1019px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 740px) and (max-width: 1019px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 740px) and (max-width: 1019px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1020px) and (max-width: 1259px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 1020px) and (max-width: 1259px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 1020px) and (max-width: 1259px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 1020px) and (max-width: 1259px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1260px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1260px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1260px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1260px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 739px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 740px) and (max-width: 1019px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 1020px) and (max-width: 1259px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1260px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.no-margin {
  margin: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
#darkness {
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: background-color 150ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: background-color 150ms cubic-bezier(0, 0, 0.2, 1);
  transition: background-color 150ms cubic-bezier(0, 0, 0.2, 1);
}
#darkness.lightbox {
  cursor: zoom-out;
}
#darkness.lifted {
  z-index: 2;
}
#darkness.active {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.14);
}
#darkness.dark {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.48);
}
#darkness.darker {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.98);
}
body.breakpoint:before {
  background-color: pink;
  content: "xs";
  position: fixed;
  z-index: 998;
}
@media (min-width: 740px) {
  body.breakpoint:before {
    content: "sm";
  }
}
@media (min-width: 1020px) {
  body.breakpoint:before {
    content: "md";
  }
}
@media (min-width: 1260px) {
  body.breakpoint:before {
    content: "lg";
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.6;
}
body.no-scroll {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  color: #009fdf;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  line-height: 1.2em;
}
h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small,
h4 small,
h4 .small,
.h4 small,
.h4 .small {
  color: inherit;
  font-size: 65%;
  font-weight: normal;
  line-height: 1;
}
h1 .c-tw-red,
.h1 .c-tw-red,
h2 .c-tw-red,
.h2 .c-tw-red,
h3 .c-tw-red,
.h3 .c-tw-red,
h4 .c-tw-red,
.h4 .c-tw-red {
  color: $red;
}
h1,
.h1 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media (min-width: 740px) {
  h1,
  .h1 {
    font-size: 32px;
    margin-bottom: 25px;
    margin-top: 25px;
  }
}
.fixed-width h1,
.fixed-width .h1 {
  font-size: 32px;
  margin-bottom: 25px;
  margin-top: 25px;
}
h2,
.h2 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (min-width: 740px) {
  h2,
  .h2 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.fixed-width h2,
.fixed-width .h2 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
}
h3,
.h3 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (min-width: 740px) {
  h3,
  .h3 {
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
.fixed-width h3,
.fixed-width .h3 {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
}
h4,
.h4 {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (min-width: 740px) {
  h4,
  .h4 {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
.fixed-width h4,
.fixed-width .h4 {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
}
h2 + p,
h2 + address,
.h2 + p,
.h2 + address,
h3 + p,
h3 + address,
.h3 + p,
.h3 + address,
h4 + p,
h4 + address,
.h4 + p,
.h4 + address {
  margin-top: -5px;
}
p {
  color: #000;
  font-family: Arial, "Helvetica Neue", "Helvetica", sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (min-width: 740px) {
  p {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
p.lead {
  font-size: 16px;
}
p.small {
  line-height: 1.8em;
}
p.small else {
  font-size: 11px;
}
p.no-margin {
  margin-bottom: 0;
}
.lead-paragraph p {
  font-size: 16px;
}
.fixed-width p {
  margin-bottom: 15px;
  margin-top: 15px;
}
p a,
ul a,
ol a {
  color: #7fba00;
}
p a:hover,
ul a:hover,
ol a:hover {
  color: #4d8504;
  text-decoration: underline;
}
.primary-link,a[rel="tel"],a[href*="tel://"] {
  color: #7fba00;
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2em;
  margin: 20px 0 12px;
}
.primary-link:after {
  content: "\f138";
  font-family: "FontAwesome";
  font-size: 14px;
  margin-left: 5px;
}
.primary-link:hover {
  color: #4d8504;
  text-decoration: none;
}
.primary-link + .primary-link {
  border-top: 1px solid #dae6e2;
  margin-top: 12px;
  padding-top: 12px;
}
a[rel="tel"]:before,a[href*="tel://"]:before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #7fba00;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  content: "\f095";
  font-size: 16px;
  height: 24px;
  line-height: 1;
  margin: -4px 8px -4px 0;
  padding: 4px;
  text-align: center;
  width: 24px;
}
a[rel="tel"]:hover,a[href*="tel://"]:hover {
  color: #4d8504;
  text-decoration: none;
}
a[rel="tel"]+a[rel="tel"],a[rel="tel"]+a[href*="tel://"],a[href*="tel://"]+a[rel="tel"],a[href*="tel://"]+a[href*="tel://"] {
  border-top: 1px solid #dae6e2;
  margin-top: 12px;
  padding-top: 12px;
}
p > img {
  display: block;
  height: auto;
  min-width: 100%;
  width: 100%;
}
small,
.small {
  line-height: 1.8em;
}
small else,
.small else {
  font-size: 11px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
ul,
ol {
  padding-left: 0;
  margin-left: 25px;
}
ul ul,
ul ol,
ol ul,
ol ol {
  margin-left: 20px;
}
ol {
  list-style: decimal inside;
}
ul.bullet,
ol,
ul:not(.nav-level):not(.tout-list):not(.social-badges):not(.fa-ul):not(.level):not(.pagination):not(.pager):not(.option-list):not(.downloads):not(.form-autocomplete):not(.gallery-list):not(.btn-container):not(.plumber-articles):not(.chosen-results) {
  margin-left: 26px;
  text-indent: -18px;
  padding-left: 0;
}
ul.bullet,
ul:not(.nav-level):not(.tout-list):not(.fa-ul):not(.level):not(.pagination):not(.pager):not(.option-list):not(.downloads):not(.form-autocomplete):not(.gallery-list):not(.btn-container):not(.plumber-articles):not(.chosen-results) {
  list-style: none;
}
ul.bullet li::before,
ul:not(.nav-level):not(.tout-list):not(.fa-ul):not(.level):not(.pagination):not(.pager):not(.option-list):not(.downloads):not(.form-autocomplete):not(.gallery-list):not(.btn-container):not(.plumber-articles):not(.chosen-results)
  li::before {
  color: #009fdf;
  content: "\f111";
  font-family: "FontAwesome";
  font-size: 9px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}
.map-key-container ul {
  margin-left: 0 !important;
  text-indent: 0 !important;
  padding-left: 10px !important;
}
.map-key-container ul li::before {
  content: "" !important;
}
.options .option-list {
  list-style: none;
  margin: 0;
}
ul.plumber-articles {
  margin-left: inherit;
}
ol.bullet li::before {
  color: #009fdf;
  content: "\f111";
  font-family: "FontAwesome";
  font-size: 9px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}
ol.decimal,
ol.decimal-leading-zero,
ol.lower-roman,
ol.upper-roman,
ol.lower-alpha,
ol.upper-alpha {
  list-style-type: none;
}
ol.decimal li,
ol.decimal-leading-zero li,
ol.lower-roman li,
ol.upper-roman li,
ol.lower-alpha li,
ol.upper-alpha li {
  position: relative;
  text-indent: 0;
}
ol.decimal li:before,
ol.decimal-leading-zero li:before,
ol.lower-roman li:before,
ol.upper-roman li:before,
ol.lower-alpha li:before,
ol.upper-alpha li:before {
  color: #009fdf;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: 600;
  left: -26px;
  line-height: 22px;
  position: absolute;
  text-align: right;
  top: 0;
  width: 18px;
}
ol.decimal {
  counter-reset: decimal;
}
ol.decimal li::before {
  counter-increment: decimal;
  content: counter(decimal, decimal) ". ";
}
ol.decimal-leading-zero {
  counter-reset: decimal-leading-zero;
}
ol.decimal-leading-zero li::before {
  counter-increment: decimal-leading-zero;
  content: counter(decimal-leading-zero, decimal-leading-zero) ". ";
}
ol.lower-roman {
  counter-reset: lower-roman;
}
ol.lower-roman li::before {
  counter-increment: lower-roman;
  content: counter(lower-roman, lower-roman) ". ";
}
ol.upper-roman {
  counter-reset: upper-roman;
}
ol.upper-roman li::before {
  counter-increment: upper-roman;
  content: counter(upper-roman, upper-roman) ". ";
}
ol.lower-alpha {
  counter-reset: lower-alpha;
}
ol.lower-alpha li::before {
  counter-increment: lower-alpha;
  content: counter(lower-alpha, lower-alpha) ". ";
}
ol.upper-alpha {
  counter-reset: upper-alpha;
}
ol.upper-alpha li::before {
  counter-increment: upper-alpha;
  content: counter(upper-alpha, upper-alpha) ". ";
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled > li::before {
  content: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li::before {
  content: none;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-top: 0;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
blockquote {
  padding: 0.33333em 1.5em;
  margin: 0 0 1.2em 2em;
  border-left: 5px solid #dae6e2;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1;
  color: #71857e;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: "— ";
}
.blockquote-reverse {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #dae6e2;
  border-left: 0;
  margin-right: 2em;
  text-align: right;
}
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before {
  content: "";
}
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after {
  content: " —";
}
.panel .panel-body > h2,
.panel .panel-body > h3 {
  margin-top: 10px;
}
.well h1:first-child,
.well h2:first-child,
.well h3:first-child,
.well h4:first-child,
.well ul:first-child,
.well ol:first-child,
.well p:first-child,
.well div:first-child {
  margin-top: 0;
}
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: 0;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: 0;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.flickity-prev-next-button:hover {
  background: white;
}
.flickity-prev-next-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #09f;
}
.flickity-prev-next-button:active {
  opacity: 0.6;
}
.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}
.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
.flickity-prev-next-button .arrow {
  fill: #333;
}
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-rtl .flickity-page-dots {
  direction: rtl;
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.chosen-select {
  width: 100%;
}
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  user-select: none;
  width: 100%;
}
.chosen-container * {
  box-sizing: border-box;
}
.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  z-index: 1010;
  width: 100%;
  border: 1px solid #009fdf;
  background: #fff;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  clip: rect(0, 0, 0, 0);
}
.chosen-container.chosen-with-drop .chosen-drop {
  clip: auto;
}
.chosen-container a {
  cursor: pointer;
}
.chosen-container .search-choice .group-name,
.chosen-container .chosen-single .group-name {
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #999;
}
.chosen-container .search-choice .group-name:after,
.chosen-container .chosen-single .group-name:after {
  content: ":";
  padding-left: 2px;
  vertical-align: top;
}
.chosen-container-single .chosen-single {
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 2px solid #dae6e2;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  z-index: 9999;
}
.chosen-container-single .chosen-default {
  color: #555;
}
.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}
.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("/assets/images/chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%;
}
.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/assets/images/chosen-sprite.png") no-repeat 0 5px;
}
.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
  height: auto;
  outline: 0;
  border: 1px solid #aaa;
  background: url("/assets/images/chosen-sprite.png") no-repeat 100% -20px;
  font-size: 1em;
  font-family: sans-serif;
  line-height: normal;
  border-radius: 5px;
}
.chosen-container-single .chosen-drop {
  margin-top: 3px;
  border-radius: 4px;
  background-clip: padding-box;
  z-index: 99999;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.chosen-container .chosen-results {
  color: #444;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 4px 0 0 4px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  margin: 0;
  padding: 5px 6px;
  list-style: none;
  line-height: 15px;
  word-wrap: break-word;
  -webkit-touch-callout: none;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #009fdf;
  color: #fff;
}
.chosen-container .chosen-results li.no-results {
  color: #777;
  display: list-item;
  background: #f4f4f4;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  height: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: linear-gradient(#eee 1%, #fff 15%);
  cursor: text;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  margin: 1px 0;
  padding: 0;
  height: 25px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none;
  color: #999;
  font-size: 100%;
  font-family: sans-serif;
  line-height: normal;
  border-radius: 0;
  width: 25px;
}
.chosen-container-multi .chosen-choices li.search-choice {
  position: relative;
  margin: 3px 5px 3px 0;
  padding: 3px 20px 3px 5px;
  border: 1px solid #aaa;
  max-width: 100%;
  border-radius: 3px;
  background-color: #eee;
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-size: 100% 19px;
  background-repeat: repeat-x;
  background-clip: padding-box;
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  color: #333;
  line-height: 13px;
  cursor: default;
}
.chosen-container-multi .chosen-choices li.search-choice span {
  word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 4px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("/assets/images/chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #ccc;
  background-color: #e4e4e4;
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  color: #666;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}
.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-container-active .chosen-single {
  border: 2px solid #009fdf;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  box-shadow: 0 1px 0 #fff inset;
  z-index: 0;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: none;
  background: transparent;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 5px;
}
.chosen-container-active .chosen-choices {
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #222 !important;
}
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}
.chosen-rtl {
  text-align: right;
}
.chosen-rtl .chosen-single {
  overflow: visible;
  padding: 0 8px 0 0;
}
.chosen-rtl .chosen-single span {
  margin-right: 0;
  margin-left: 26px;
  direction: rtl;
}
.chosen-rtl .chosen-single-with-deselect span {
  margin-left: 38px;
}
.chosen-rtl .chosen-single div {
  right: auto;
  left: 3px;
}
.chosen-rtl .chosen-single abbr {
  right: auto;
  left: 26px;
}
.chosen-rtl .chosen-choices li {
  float: right;
}
.chosen-rtl .chosen-choices li.search-field input[type="text"] {
  direction: rtl;
}
.chosen-rtl .chosen-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}
.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  right: auto;
  left: 4px;
}
.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chosen-rtl .chosen-results li.group-option {
  padding-right: 15px;
  padding-left: 0;
}
.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: 0;
}
.chosen-rtl .chosen-search input[type="text"] {
  padding: 4px 5px 4px 20px;
  background: url("/assets/images/chosen-sprite.png") no-repeat -30px -20px;
  direction: rtl;
}
.chosen-rtl.chosen-container-single .chosen-single div b {
  background-position: 6px 2px;
}
.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: -12px 2px;
}
.chosen-container-single .search-container .chosen-search {
  position: absolute;
  width: calc(100% - 30px);
}
.chosen-container-single .search-container .chosen-search input {
  border: 0;
  background: 0;
  padding: 5px 10px;
  font-size: 14px;
}
.chosen-container-single.chosen-with-drop .search-container .chosen-single span {
  display: none;
}
.form-group.has-error .chosen-single {
  border-color: #d62906;
}
.form-group.has-success .chosen-single {
  border-color: #7fba00;
}
.form-group.has-focus .chosen-single,
.form-group.has-info .chosen-single {
  border-color: #009fdf;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 144dpi),
  only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("/assets/images/chosen-sprite@2x.png") !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important;
  }
  .chosen-container-single .search-container .chosen-search input {
    background-image: none !important;
  }
}
.sidebar {
  margin-top: 20px;
}
.main > section + section {
  border-top: 1px solid #dae6e2;
  margin-top: 35px;
  padding-top: 20px;
}
.main > section.hidden + section {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}
.main header {
  margin-bottom: 30px;
}
.main header:before,
.main header:after {
  content: " ";
  display: table;
}
.main header:after {
  clear: both;
}
.main header h1 {
  border-bottom: 1px solid #dae6e2;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.main header small {
  color: #71857e;
}
@media (min-width: 1020px) {
  .sidebar {
    margin-top: 20px;
  }
  .main {
    margin-top: 0;
  }
}
body.fixed-width {
  min-width: 1020px;
}
.container.container-fixed {
  width: 960px;
}
.breadcrumb-wrapper {
  border-top: 1px solid #dae6e2;
  height: 0;
  margin-bottom: 15px;
  overflow: hidden;
}
.breadcrumb {
  background: 0;
  float: left;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin: 0 auto;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 15px;
  text-indent: 0;
  visibility: hidden;
  white-space: nowrap;
  width: 100%;
}
.breadcrumb > li {
  margin-right: -4px;
}
.breadcrumb > li a {
  color: #71857e;
  display: inline-block;
  padding: 8px 10px;
  -webkit-transition: color 250ms ease-in-out;
  -o-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
}
.breadcrumb > li a:hover {
  color: #000;
  text-decoration: none;
}
.breadcrumb > li.active a {
  color: #000;
}
.breadcrumb > li + li:before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
  font-size: 9px;
  padding: 0;
  width: 5px;
}
@media (min-width: 740px) {
  .breadcrumb-wrapper.breadcrumb-wrapper {
    border-bottom: 1px solid #dae6e2;
    height: auto;
  }
  .breadcrumb-wrapper .breadcrumb {
    float: none;
    padding-left: 10px;
    padding-right: 10px;
    visibility: visible;
    width: 720px;
  }
  .breadcrumb-wrapper .breadcrumb .truncated::before {
    content: "\2026";
  }
  .breadcrumb-wrapper .breadcrumb .truncated span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
@media (min-width: 1020px) {
  .breadcrumb-wrapper .breadcrumb {
    width: 960px;
  }
}
@media (min-width: 1260px) {
  .breadcrumb-wrapper .breadcrumb {
    width: 1200px;
  }
}
.breadcrumb-fixed.breadcrumb-wrapper {
  border-bottom: 1px solid #dae6e2;
  height: auto;
}
.breadcrumb-fixed .breadcrumb {
  float: none;
  padding-left: 10px;
  padding-right: 10px;
  visibility: visible;
  width: 720px;
}
.breadcrumb-fixed .breadcrumb .truncated::before {
  content: "\2026";
}
.breadcrumb-fixed .breadcrumb .truncated span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.breadcrumb-fixed .breadcrumb {
  width: 960px;
}
.btn {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-tw-primary,
.btn-tw-secondary,
.btn-tw-primary-inverse,
.btn-tw-secondary-inverse,
.btn-tw-tertiary {
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 10px 0;
  padding: 9px 25px 9px;
}
@media (min-width: 740px) {
  .btn-tw-primary,
  .btn-tw-secondary,
  .btn-tw-primary-inverse,
  .btn-tw-secondary-inverse,
  .btn-tw-tertiary {
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    margin: 20px 0;
    padding: 11px 20px 8px;
  }
}
.btn-tw-primary,
.btn-tw-secondary,
.btn-tw-primary-inverse,
.btn-tw-secondary-inverse {
  padding: 9px 25px 9px;
}
@media (min-width: 740px) {
  .btn-tw-primary,
  .btn-tw-secondary,
  .btn-tw-primary-inverse,
  .btn-tw-secondary-inverse {
    padding: 6px 15px 6px;
  }
}
.btn-tw-primary {
  border-radius: 200px;
  color: #fff;
  background-color: #7fba00;
  border-color: #7fba00;
  border-width: 2px;
}
.btn-tw-primary:active,
.btn-tw-primary.active,
.open > .btn-tw-primary.dropdown-toggle {
  background-image: none;
}
.btn-tw-primary:hover,
.btn-tw-primary:focus,
.btn-tw-primary.focus,
.btn-tw-primary:active,
.btn-tw-primary.active {
  color: #fff;
  background-color: #4d8504;
  border-color: #4d8504;
}
.btn-tw-primary:hover:active,
.btn-tw-primary:hover.active,
.open > .btn-tw-primary:hover.dropdown-toggle,
.btn-tw-primary:focus:active,
.btn-tw-primary:focus.active,
.open > .btn-tw-primary:focus.dropdown-toggle,
.btn-tw-primary.focus:active,
.btn-tw-primary.focus.active,
.open > .btn-tw-primary.focus.dropdown-toggle,
.btn-tw-primary:active:active,
.btn-tw-primary:active.active,
.open > .btn-tw-primary:active.dropdown-toggle,
.btn-tw-primary.active:active,
.btn-tw-primary.active.active,
.open > .btn-tw-primary.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-primary.disabled,
.btn-tw-primary.disabled:hover,
.btn-tw-primary.disabled:focus,
.btn-tw-primary.disabled.focus,
.btn-tw-primary.disabled:active,
.btn-tw-primary.disabled.active,
.btn-tw-primary[disabled],
.btn-tw-primary[disabled]:hover,
.btn-tw-primary[disabled]:focus,
.btn-tw-primary[disabled].focus,
.btn-tw-primary[disabled]:active,
.btn-tw-primary[disabled].active,
fieldset[disabled] .btn-tw-primary,
fieldset[disabled] .btn-tw-primary:hover,
fieldset[disabled] .btn-tw-primary:focus,
fieldset[disabled] .btn-tw-primary.focus,
fieldset[disabled] .btn-tw-primary:active,
fieldset[disabled] .btn-tw-primary.active {
  color: #fff;
  background-color: #abbcb6;
  border-color: #abbcb6;
}
.btn-tw-primary.disabled:active,
.btn-tw-primary.disabled.active,
.open > .btn-tw-primary.disabled.dropdown-toggle,
.btn-tw-primary.disabled:hover:active,
.btn-tw-primary.disabled:hover.active,
.open > .btn-tw-primary.disabled:hover.dropdown-toggle,
.btn-tw-primary.disabled:focus:active,
.btn-tw-primary.disabled:focus.active,
.open > .btn-tw-primary.disabled:focus.dropdown-toggle,
.btn-tw-primary.disabled.focus:active,
.btn-tw-primary.disabled.focus.active,
.open > .btn-tw-primary.disabled.focus.dropdown-toggle,
.btn-tw-primary.disabled:active:active,
.btn-tw-primary.disabled:active.active,
.open > .btn-tw-primary.disabled:active.dropdown-toggle,
.btn-tw-primary.disabled.active:active,
.btn-tw-primary.disabled.active.active,
.open > .btn-tw-primary.disabled.active.dropdown-toggle,
.btn-tw-primary[disabled]:active,
.btn-tw-primary[disabled].active,
.open > .btn-tw-primary[disabled].dropdown-toggle,
.btn-tw-primary[disabled]:hover:active,
.btn-tw-primary[disabled]:hover.active,
.open > .btn-tw-primary[disabled]:hover.dropdown-toggle,
.btn-tw-primary[disabled]:focus:active,
.btn-tw-primary[disabled]:focus.active,
.open > .btn-tw-primary[disabled]:focus.dropdown-toggle,
.btn-tw-primary[disabled].focus:active,
.btn-tw-primary[disabled].focus.active,
.open > .btn-tw-primary[disabled].focus.dropdown-toggle,
.btn-tw-primary[disabled]:active:active,
.btn-tw-primary[disabled]:active.active,
.open > .btn-tw-primary[disabled]:active.dropdown-toggle,
.btn-tw-primary[disabled].active:active,
.btn-tw-primary[disabled].active.active,
.open > .btn-tw-primary[disabled].active.dropdown-toggle,
fieldset[disabled] .btn-tw-primary:active,
fieldset[disabled] .btn-tw-primary.active,
.open > fieldset[disabled] .btn-tw-primary.dropdown-toggle,
fieldset[disabled] .btn-tw-primary:hover:active,
fieldset[disabled] .btn-tw-primary:hover.active,
.open > fieldset[disabled] .btn-tw-primary:hover.dropdown-toggle,
fieldset[disabled] .btn-tw-primary:focus:active,
fieldset[disabled] .btn-tw-primary:focus.active,
.open > fieldset[disabled] .btn-tw-primary:focus.dropdown-toggle,
fieldset[disabled] .btn-tw-primary.focus:active,
fieldset[disabled] .btn-tw-primary.focus.active,
.open > fieldset[disabled] .btn-tw-primary.focus.dropdown-toggle,
fieldset[disabled] .btn-tw-primary:active:active,
fieldset[disabled] .btn-tw-primary:active.active,
.open > fieldset[disabled] .btn-tw-primary:active.dropdown-toggle,
fieldset[disabled] .btn-tw-primary.active:active,
fieldset[disabled] .btn-tw-primary.active.active,
.open > fieldset[disabled] .btn-tw-primary.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-primary-inverse {
  border-radius: 200px;
  color: #7fba00;
  background-color: #fff;
  border-color: #fff;
  border-width: 2px;
}
.btn-tw-primary-inverse:active,
.btn-tw-primary-inverse.active,
.open > .btn-tw-primary-inverse.dropdown-toggle {
  background-image: none;
}
.btn-tw-primary-inverse:hover,
.btn-tw-primary-inverse:focus,
.btn-tw-primary-inverse.focus,
.btn-tw-primary-inverse:active,
.btn-tw-primary-inverse.active {
  color: #4d8504;
  background-color: #fff;
  border-color: #fff;
}
.btn-tw-primary-inverse:hover:active,
.btn-tw-primary-inverse:hover.active,
.open > .btn-tw-primary-inverse:hover.dropdown-toggle,
.btn-tw-primary-inverse:focus:active,
.btn-tw-primary-inverse:focus.active,
.open > .btn-tw-primary-inverse:focus.dropdown-toggle,
.btn-tw-primary-inverse.focus:active,
.btn-tw-primary-inverse.focus.active,
.open > .btn-tw-primary-inverse.focus.dropdown-toggle,
.btn-tw-primary-inverse:active:active,
.btn-tw-primary-inverse:active.active,
.open > .btn-tw-primary-inverse:active.dropdown-toggle,
.btn-tw-primary-inverse.active:active,
.btn-tw-primary-inverse.active.active,
.open > .btn-tw-primary-inverse.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-primary-inverse.disabled,
.btn-tw-primary-inverse.disabled:hover,
.btn-tw-primary-inverse.disabled:focus,
.btn-tw-primary-inverse.disabled.focus,
.btn-tw-primary-inverse.disabled:active,
.btn-tw-primary-inverse.disabled.active,
.btn-tw-primary-inverse[disabled],
.btn-tw-primary-inverse[disabled]:hover,
.btn-tw-primary-inverse[disabled]:focus,
.btn-tw-primary-inverse[disabled].focus,
.btn-tw-primary-inverse[disabled]:active,
.btn-tw-primary-inverse[disabled].active,
fieldset[disabled] .btn-tw-primary-inverse,
fieldset[disabled] .btn-tw-primary-inverse:hover,
fieldset[disabled] .btn-tw-primary-inverse:focus,
fieldset[disabled] .btn-tw-primary-inverse.focus,
fieldset[disabled] .btn-tw-primary-inverse:active,
fieldset[disabled] .btn-tw-primary-inverse.active {
  color: #fff;
  background-color: #abbcb6;
  border-color: #abbcb6;
}
.btn-tw-primary-inverse.disabled:active,
.btn-tw-primary-inverse.disabled.active,
.open > .btn-tw-primary-inverse.disabled.dropdown-toggle,
.btn-tw-primary-inverse.disabled:hover:active,
.btn-tw-primary-inverse.disabled:hover.active,
.open > .btn-tw-primary-inverse.disabled:hover.dropdown-toggle,
.btn-tw-primary-inverse.disabled:focus:active,
.btn-tw-primary-inverse.disabled:focus.active,
.open > .btn-tw-primary-inverse.disabled:focus.dropdown-toggle,
.btn-tw-primary-inverse.disabled.focus:active,
.btn-tw-primary-inverse.disabled.focus.active,
.open > .btn-tw-primary-inverse.disabled.focus.dropdown-toggle,
.btn-tw-primary-inverse.disabled:active:active,
.btn-tw-primary-inverse.disabled:active.active,
.open > .btn-tw-primary-inverse.disabled:active.dropdown-toggle,
.btn-tw-primary-inverse.disabled.active:active,
.btn-tw-primary-inverse.disabled.active.active,
.open > .btn-tw-primary-inverse.disabled.active.dropdown-toggle,
.btn-tw-primary-inverse[disabled]:active,
.btn-tw-primary-inverse[disabled].active,
.open > .btn-tw-primary-inverse[disabled].dropdown-toggle,
.btn-tw-primary-inverse[disabled]:hover:active,
.btn-tw-primary-inverse[disabled]:hover.active,
.open > .btn-tw-primary-inverse[disabled]:hover.dropdown-toggle,
.btn-tw-primary-inverse[disabled]:focus:active,
.btn-tw-primary-inverse[disabled]:focus.active,
.open > .btn-tw-primary-inverse[disabled]:focus.dropdown-toggle,
.btn-tw-primary-inverse[disabled].focus:active,
.btn-tw-primary-inverse[disabled].focus.active,
.open > .btn-tw-primary-inverse[disabled].focus.dropdown-toggle,
.btn-tw-primary-inverse[disabled]:active:active,
.btn-tw-primary-inverse[disabled]:active.active,
.open > .btn-tw-primary-inverse[disabled]:active.dropdown-toggle,
.btn-tw-primary-inverse[disabled].active:active,
.btn-tw-primary-inverse[disabled].active.active,
.open > .btn-tw-primary-inverse[disabled].active.dropdown-toggle,
fieldset[disabled] .btn-tw-primary-inverse:active,
fieldset[disabled] .btn-tw-primary-inverse.active,
.open > fieldset[disabled] .btn-tw-primary-inverse.dropdown-toggle,
fieldset[disabled] .btn-tw-primary-inverse:hover:active,
fieldset[disabled] .btn-tw-primary-inverse:hover.active,
.open > fieldset[disabled] .btn-tw-primary-inverse:hover.dropdown-toggle,
fieldset[disabled] .btn-tw-primary-inverse:focus:active,
fieldset[disabled] .btn-tw-primary-inverse:focus.active,
.open > fieldset[disabled] .btn-tw-primary-inverse:focus.dropdown-toggle,
fieldset[disabled] .btn-tw-primary-inverse.focus:active,
fieldset[disabled] .btn-tw-primary-inverse.focus.active,
.open > fieldset[disabled] .btn-tw-primary-inverse.focus.dropdown-toggle,
fieldset[disabled] .btn-tw-primary-inverse:active:active,
fieldset[disabled] .btn-tw-primary-inverse:active.active,
.open > fieldset[disabled] .btn-tw-primary-inverse:active.dropdown-toggle,
fieldset[disabled] .btn-tw-primary-inverse.active:active,
fieldset[disabled] .btn-tw-primary-inverse.active.active,
.open > fieldset[disabled] .btn-tw-primary-inverse.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-secondary {
  border-radius: 200px;
  color: #7fba00;
  background-color: #fff;
  border-color: #7fba00;
  border-width: 2px;
}
.btn-tw-secondary:active,
.btn-tw-secondary.active,
.open > .btn-tw-secondary.dropdown-toggle {
  background-image: none;
}
.btn-tw-secondary:hover,
.btn-tw-secondary:focus,
.btn-tw-secondary.focus,
.btn-tw-secondary:active,
.btn-tw-secondary.active {
  color: #4d8504;
  background-color: #fff;
  border-color: #4d8504;
}
.btn-tw-secondary:hover:active,
.btn-tw-secondary:hover.active,
.open > .btn-tw-secondary:hover.dropdown-toggle,
.btn-tw-secondary:focus:active,
.btn-tw-secondary:focus.active,
.open > .btn-tw-secondary:focus.dropdown-toggle,
.btn-tw-secondary.focus:active,
.btn-tw-secondary.focus.active,
.open > .btn-tw-secondary.focus.dropdown-toggle,
.btn-tw-secondary:active:active,
.btn-tw-secondary:active.active,
.open > .btn-tw-secondary:active.dropdown-toggle,
.btn-tw-secondary.active:active,
.btn-tw-secondary.active.active,
.open > .btn-tw-secondary.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-secondary.disabled,
.btn-tw-secondary.disabled:hover,
.btn-tw-secondary.disabled:focus,
.btn-tw-secondary.disabled.focus,
.btn-tw-secondary.disabled:active,
.btn-tw-secondary.disabled.active,
.btn-tw-secondary[disabled],
.btn-tw-secondary[disabled]:hover,
.btn-tw-secondary[disabled]:focus,
.btn-tw-secondary[disabled].focus,
.btn-tw-secondary[disabled]:active,
.btn-tw-secondary[disabled].active,
fieldset[disabled] .btn-tw-secondary,
fieldset[disabled] .btn-tw-secondary:hover,
fieldset[disabled] .btn-tw-secondary:focus,
fieldset[disabled] .btn-tw-secondary.focus,
fieldset[disabled] .btn-tw-secondary:active,
fieldset[disabled] .btn-tw-secondary.active {
  background-color: #fff;
  border-color: #abbcb6;
}
.btn-tw-secondary.disabled:active,
.btn-tw-secondary.disabled.active,
.open > .btn-tw-secondary.disabled.dropdown-toggle,
.btn-tw-secondary.disabled:hover:active,
.btn-tw-secondary.disabled:hover.active,
.open > .btn-tw-secondary.disabled:hover.dropdown-toggle,
.btn-tw-secondary.disabled:focus:active,
.btn-tw-secondary.disabled:focus.active,
.open > .btn-tw-secondary.disabled:focus.dropdown-toggle,
.btn-tw-secondary.disabled.focus:active,
.btn-tw-secondary.disabled.focus.active,
.open > .btn-tw-secondary.disabled.focus.dropdown-toggle,
.btn-tw-secondary.disabled:active:active,
.btn-tw-secondary.disabled:active.active,
.open > .btn-tw-secondary.disabled:active.dropdown-toggle,
.btn-tw-secondary.disabled.active:active,
.btn-tw-secondary.disabled.active.active,
.open > .btn-tw-secondary.disabled.active.dropdown-toggle,
.btn-tw-secondary[disabled]:active,
.btn-tw-secondary[disabled].active,
.open > .btn-tw-secondary[disabled].dropdown-toggle,
.btn-tw-secondary[disabled]:hover:active,
.btn-tw-secondary[disabled]:hover.active,
.open > .btn-tw-secondary[disabled]:hover.dropdown-toggle,
.btn-tw-secondary[disabled]:focus:active,
.btn-tw-secondary[disabled]:focus.active,
.open > .btn-tw-secondary[disabled]:focus.dropdown-toggle,
.btn-tw-secondary[disabled].focus:active,
.btn-tw-secondary[disabled].focus.active,
.open > .btn-tw-secondary[disabled].focus.dropdown-toggle,
.btn-tw-secondary[disabled]:active:active,
.btn-tw-secondary[disabled]:active.active,
.open > .btn-tw-secondary[disabled]:active.dropdown-toggle,
.btn-tw-secondary[disabled].active:active,
.btn-tw-secondary[disabled].active.active,
.open > .btn-tw-secondary[disabled].active.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary:active,
fieldset[disabled] .btn-tw-secondary.active,
.open > fieldset[disabled] .btn-tw-secondary.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary:hover:active,
fieldset[disabled] .btn-tw-secondary:hover.active,
.open > fieldset[disabled] .btn-tw-secondary:hover.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary:focus:active,
fieldset[disabled] .btn-tw-secondary:focus.active,
.open > fieldset[disabled] .btn-tw-secondary:focus.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary.focus:active,
fieldset[disabled] .btn-tw-secondary.focus.active,
.open > fieldset[disabled] .btn-tw-secondary.focus.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary:active:active,
fieldset[disabled] .btn-tw-secondary:active.active,
.open > fieldset[disabled] .btn-tw-secondary:active.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary.active:active,
fieldset[disabled] .btn-tw-secondary.active.active,
.open > fieldset[disabled] .btn-tw-secondary.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-secondary-inverse {
  border-radius: 200px;
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border-width: 2px;
}
.btn-tw-secondary-inverse:active,
.btn-tw-secondary-inverse.active,
.open > .btn-tw-secondary-inverse.dropdown-toggle {
  background-image: none;
}
.btn-tw-secondary-inverse:hover,
.btn-tw-secondary-inverse:focus,
.btn-tw-secondary-inverse.focus,
.btn-tw-secondary-inverse:active,
.btn-tw-secondary-inverse.active {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  text-decoration: underline;
}
.btn-tw-secondary-inverse:hover:active,
.btn-tw-secondary-inverse:hover.active,
.open > .btn-tw-secondary-inverse:hover.dropdown-toggle,
.btn-tw-secondary-inverse:focus:active,
.btn-tw-secondary-inverse:focus.active,
.open > .btn-tw-secondary-inverse:focus.dropdown-toggle,
.btn-tw-secondary-inverse.focus:active,
.btn-tw-secondary-inverse.focus.active,
.open > .btn-tw-secondary-inverse.focus.dropdown-toggle,
.btn-tw-secondary-inverse:active:active,
.btn-tw-secondary-inverse:active.active,
.open > .btn-tw-secondary-inverse:active.dropdown-toggle,
.btn-tw-secondary-inverse.active:active,
.btn-tw-secondary-inverse.active.active,
.open > .btn-tw-secondary-inverse.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-secondary-inverse:hover {
  box-shadow: inset 0 0 0 1px #fff;
  text-decoration: none;
}
.btn-tw-secondary-inverse.disabled,
.btn-tw-secondary-inverse.disabled:hover,
.btn-tw-secondary-inverse.disabled:focus,
.btn-tw-secondary-inverse.disabled.focus,
.btn-tw-secondary-inverse.disabled:active,
.btn-tw-secondary-inverse.disabled.active,
.btn-tw-secondary-inverse[disabled],
.btn-tw-secondary-inverse[disabled]:hover,
.btn-tw-secondary-inverse[disabled]:focus,
.btn-tw-secondary-inverse[disabled].focus,
.btn-tw-secondary-inverse[disabled]:active,
.btn-tw-secondary-inverse[disabled].active,
fieldset[disabled] .btn-tw-secondary-inverse,
fieldset[disabled] .btn-tw-secondary-inverse:hover,
fieldset[disabled] .btn-tw-secondary-inverse:focus,
fieldset[disabled] .btn-tw-secondary-inverse.focus,
fieldset[disabled] .btn-tw-secondary-inverse:active,
fieldset[disabled] .btn-tw-secondary-inverse.active {
  background-color: transparent;
  border-color: #abbcb6;
}
.btn-tw-secondary-inverse.disabled:active,
.btn-tw-secondary-inverse.disabled.active,
.open > .btn-tw-secondary-inverse.disabled.dropdown-toggle,
.btn-tw-secondary-inverse.disabled:hover:active,
.btn-tw-secondary-inverse.disabled:hover.active,
.open > .btn-tw-secondary-inverse.disabled:hover.dropdown-toggle,
.btn-tw-secondary-inverse.disabled:focus:active,
.btn-tw-secondary-inverse.disabled:focus.active,
.open > .btn-tw-secondary-inverse.disabled:focus.dropdown-toggle,
.btn-tw-secondary-inverse.disabled.focus:active,
.btn-tw-secondary-inverse.disabled.focus.active,
.open > .btn-tw-secondary-inverse.disabled.focus.dropdown-toggle,
.btn-tw-secondary-inverse.disabled:active:active,
.btn-tw-secondary-inverse.disabled:active.active,
.open > .btn-tw-secondary-inverse.disabled:active.dropdown-toggle,
.btn-tw-secondary-inverse.disabled.active:active,
.btn-tw-secondary-inverse.disabled.active.active,
.open > .btn-tw-secondary-inverse.disabled.active.dropdown-toggle,
.btn-tw-secondary-inverse[disabled]:active,
.btn-tw-secondary-inverse[disabled].active,
.open > .btn-tw-secondary-inverse[disabled].dropdown-toggle,
.btn-tw-secondary-inverse[disabled]:hover:active,
.btn-tw-secondary-inverse[disabled]:hover.active,
.open > .btn-tw-secondary-inverse[disabled]:hover.dropdown-toggle,
.btn-tw-secondary-inverse[disabled]:focus:active,
.btn-tw-secondary-inverse[disabled]:focus.active,
.open > .btn-tw-secondary-inverse[disabled]:focus.dropdown-toggle,
.btn-tw-secondary-inverse[disabled].focus:active,
.btn-tw-secondary-inverse[disabled].focus.active,
.open > .btn-tw-secondary-inverse[disabled].focus.dropdown-toggle,
.btn-tw-secondary-inverse[disabled]:active:active,
.btn-tw-secondary-inverse[disabled]:active.active,
.open > .btn-tw-secondary-inverse[disabled]:active.dropdown-toggle,
.btn-tw-secondary-inverse[disabled].active:active,
.btn-tw-secondary-inverse[disabled].active.active,
.open > .btn-tw-secondary-inverse[disabled].active.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary-inverse:active,
fieldset[disabled] .btn-tw-secondary-inverse.active,
.open > fieldset[disabled] .btn-tw-secondary-inverse.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary-inverse:hover:active,
fieldset[disabled] .btn-tw-secondary-inverse:hover.active,
.open > fieldset[disabled] .btn-tw-secondary-inverse:hover.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary-inverse:focus:active,
fieldset[disabled] .btn-tw-secondary-inverse:focus.active,
.open > fieldset[disabled] .btn-tw-secondary-inverse:focus.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary-inverse.focus:active,
fieldset[disabled] .btn-tw-secondary-inverse.focus.active,
.open > fieldset[disabled] .btn-tw-secondary-inverse.focus.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary-inverse:active:active,
fieldset[disabled] .btn-tw-secondary-inverse:active.active,
.open > fieldset[disabled] .btn-tw-secondary-inverse:active.dropdown-toggle,
fieldset[disabled] .btn-tw-secondary-inverse.active:active,
fieldset[disabled] .btn-tw-secondary-inverse.active.active,
.open > fieldset[disabled] .btn-tw-secondary-inverse.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-tertiary {
  color: #7fba00;
  background-color: #fff;
  border-color: #fff;
  text-decoration: underline;
  border-width: 2px;
}
.btn-tw-tertiary:active,
.btn-tw-tertiary.active,
.open > .btn-tw-tertiary.dropdown-toggle {
  background-image: none;
}
.btn-tw-tertiary:hover,
.btn-tw-tertiary:focus,
.btn-tw-tertiary.focus,
.btn-tw-tertiary:active,
.btn-tw-tertiary.active {
  color: #4d8504;
  background-color: #fff;
  border-color: #fff;
  text-decoration: underline;
}
.btn-tw-tertiary:hover:active,
.btn-tw-tertiary:hover.active,
.open > .btn-tw-tertiary:hover.dropdown-toggle,
.btn-tw-tertiary:focus:active,
.btn-tw-tertiary:focus.active,
.open > .btn-tw-tertiary:focus.dropdown-toggle,
.btn-tw-tertiary.focus:active,
.btn-tw-tertiary.focus.active,
.open > .btn-tw-tertiary.focus.dropdown-toggle,
.btn-tw-tertiary:active:active,
.btn-tw-tertiary:active.active,
.open > .btn-tw-tertiary:active.dropdown-toggle,
.btn-tw-tertiary.active:active,
.btn-tw-tertiary.active.active,
.open > .btn-tw-tertiary.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-tertiary.disabled,
.btn-tw-tertiary.disabled:hover,
.btn-tw-tertiary.disabled:focus,
.btn-tw-tertiary.disabled.focus,
.btn-tw-tertiary.disabled:active,
.btn-tw-tertiary.disabled.active,
.btn-tw-tertiary[disabled],
.btn-tw-tertiary[disabled]:hover,
.btn-tw-tertiary[disabled]:focus,
.btn-tw-tertiary[disabled].focus,
.btn-tw-tertiary[disabled]:active,
.btn-tw-tertiary[disabled].active,
fieldset[disabled] .btn-tw-tertiary,
fieldset[disabled] .btn-tw-tertiary:hover,
fieldset[disabled] .btn-tw-tertiary:focus,
fieldset[disabled] .btn-tw-tertiary.focus,
fieldset[disabled] .btn-tw-tertiary:active,
fieldset[disabled] .btn-tw-tertiary.active {
  background-color: #fff;
  border-color: #fff;
}
.btn-tw-tertiary.disabled:active,
.btn-tw-tertiary.disabled.active,
.open > .btn-tw-tertiary.disabled.dropdown-toggle,
.btn-tw-tertiary.disabled:hover:active,
.btn-tw-tertiary.disabled:hover.active,
.open > .btn-tw-tertiary.disabled:hover.dropdown-toggle,
.btn-tw-tertiary.disabled:focus:active,
.btn-tw-tertiary.disabled:focus.active,
.open > .btn-tw-tertiary.disabled:focus.dropdown-toggle,
.btn-tw-tertiary.disabled.focus:active,
.btn-tw-tertiary.disabled.focus.active,
.open > .btn-tw-tertiary.disabled.focus.dropdown-toggle,
.btn-tw-tertiary.disabled:active:active,
.btn-tw-tertiary.disabled:active.active,
.open > .btn-tw-tertiary.disabled:active.dropdown-toggle,
.btn-tw-tertiary.disabled.active:active,
.btn-tw-tertiary.disabled.active.active,
.open > .btn-tw-tertiary.disabled.active.dropdown-toggle,
.btn-tw-tertiary[disabled]:active,
.btn-tw-tertiary[disabled].active,
.open > .btn-tw-tertiary[disabled].dropdown-toggle,
.btn-tw-tertiary[disabled]:hover:active,
.btn-tw-tertiary[disabled]:hover.active,
.open > .btn-tw-tertiary[disabled]:hover.dropdown-toggle,
.btn-tw-tertiary[disabled]:focus:active,
.btn-tw-tertiary[disabled]:focus.active,
.open > .btn-tw-tertiary[disabled]:focus.dropdown-toggle,
.btn-tw-tertiary[disabled].focus:active,
.btn-tw-tertiary[disabled].focus.active,
.open > .btn-tw-tertiary[disabled].focus.dropdown-toggle,
.btn-tw-tertiary[disabled]:active:active,
.btn-tw-tertiary[disabled]:active.active,
.open > .btn-tw-tertiary[disabled]:active.dropdown-toggle,
.btn-tw-tertiary[disabled].active:active,
.btn-tw-tertiary[disabled].active.active,
.open > .btn-tw-tertiary[disabled].active.dropdown-toggle,
fieldset[disabled] .btn-tw-tertiary:active,
fieldset[disabled] .btn-tw-tertiary.active,
.open > fieldset[disabled] .btn-tw-tertiary.dropdown-toggle,
fieldset[disabled] .btn-tw-tertiary:hover:active,
fieldset[disabled] .btn-tw-tertiary:hover.active,
.open > fieldset[disabled] .btn-tw-tertiary:hover.dropdown-toggle,
fieldset[disabled] .btn-tw-tertiary:focus:active,
fieldset[disabled] .btn-tw-tertiary:focus.active,
.open > fieldset[disabled] .btn-tw-tertiary:focus.dropdown-toggle,
fieldset[disabled] .btn-tw-tertiary.focus:active,
fieldset[disabled] .btn-tw-tertiary.focus.active,
.open > fieldset[disabled] .btn-tw-tertiary.focus.dropdown-toggle,
fieldset[disabled] .btn-tw-tertiary:active:active,
fieldset[disabled] .btn-tw-tertiary:active.active,
.open > fieldset[disabled] .btn-tw-tertiary:active.dropdown-toggle,
fieldset[disabled] .btn-tw-tertiary.active:active,
fieldset[disabled] .btn-tw-tertiary.active.active,
.open > fieldset[disabled] .btn-tw-tertiary.active.dropdown-toggle {
  background-image: none;
}
.btn-tw-primary.btn-sm,
.btn-group-sm > .btn-tw-primary.btn,
.btn-tw-secondary.btn-sm,
.btn-group-sm > .btn-tw-secondary.btn,
.btn-tw-tertiary.btn-sm,
.btn-group-sm > .btn-tw-tertiary.btn,
.btn-tw-primary-inverse.btn-sm,
.btn-group-sm > .btn-tw-primary-inverse.btn,
.btn-tw-secondary-inverse.btn-sm,
.btn-group-sm > .btn-tw-secondary-inverse.btn {
  padding: 7px 30px 7px;
}
.btn-tw-high-contrast {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  margin: 30px 0;
  touch-action: manipulation;
  padding: 6px 16px 6px 8px;
  width: 100%;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-transition-property: background-color border-color;
  transition-property: background-color border-color;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-tw-high-contrast.active {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}
.btn-tw-high-contrast:hover,
.btn-tw-high-contrast:focus {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.26);
}
.btn-tw-high-contrast:focus {
  border-color: #e8b600;
}
.btn-tw-high-contrast span {
  display: inline-block;
  vertical-align: middle;
}
.btn-tw-high-contrast .fa {
  font-size: 22px;
}
.btn-tw-high-contrast .fa + span {
  margin-left: 4px;
  margin-top: 3px;
}
@media (min-width: 740px) {
  .btn-tw-high-contrast {
    display: inline-block;
    margin: 20px 0;
    width: auto;
  }
}
.btn-tw-backtotop {
  background-color: rgba(0, 159, 223, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  bottom: 15px;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 2em;
  opacity: 0;
  padding: 0;
  position: fixed;
  right: 15px;
  width: 2em;
  z-index: 1;
  -webkit-transition-property: background-color border-color opacity;
  transition-property: background-color border-color opacity;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-tw-backtotop::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
}
.btn-tw-backtotop:hover,
.btn-tw-backtotop:focus,
.btn-tw-backtotop.focus {
  background-color: #009fdf;
}
.btn-tw-backtotop:focus {
  border: 2px solid #e8b600;
  outline: 0;
}
.btn-tw-backtotop:hover {
  border-color: rgba(255, 255, 255, 0.4);
}
.btn-tw-backtotop.visible {
  opacity: 1;
}
.header {
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.header > .container {
  padding: 0;
}
.header.header-open {
  color: #fff;
  z-index: 5;
}
.header nav {
  margin: 10px 0;
}
.login,
.menu {
  background: 0;
  border: 0;
  display: block;
  font-size: 13px;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 20px;
  z-index: 1;
  margin-top: 10px;
}
.login span,
.menu span {
  display: block;
}
.login .fa,
.login .custom-bars,
.menu .fa,
.menu .custom-bars {
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 30px;
  margin-bottom: 8px;
  overflow: hidden;
  width: 30px;
}
.login {
  left: 15px;
}
.header-open .login {
  z-index: auto;
}
.login .fa {
  background-color: #009fdf;
  border-color: #009fdf;
  font-size: 26px;
  line-height: 30px;
}
.menu {
  right: 15px;
}
.menu > span + span {
  -webkit-transition: opacity 150ms ease-in-out;
  -o-transition: opacity 150ms ease-in-out;
  transition: opacity 150ms ease-in-out;
}
.header-open .menu > span + span {
  opacity: 0;
}
.menu .fa {
  background-color: #7fba00;
  border-color: #7fba00;
}
.menu .fa > span {
  will-change: transform;
  -webkit-transition: transform 150ms ease-in-out;
  -o-transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
}
.header-open .menu .fa > span {
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}
.menu .fa .fa-bars {
  font-size: 18px;
  line-height: 26px;
}
.menu .fa .fa-times {
  font-size: 20px;
  line-height: 30px;
}
.login .span-login {
  position: absolute;
  padding-top: 3px;
  top: 7px;
  width: 43px;
  margin-left: 33px;
}
.menu .span-menu {
  position: absolute;
  right: 37px;
  top: 10px;
}
.brand {
  background-image: url("thames-water-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 50px;
  margin: 20px 0;
  padding: 0;
  position: relative;
  width: 80px;
  z-index: 2;
  top: 0;
}
.header-open .brand {
  background-image: url("thames-water-logo-reversed.svg");
}
.brand img {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.collapsable {
  height: 0;
  overflow: hidden;
  position: absolute;
  padding-bottom: 4px;
  top: 0;
  width: 100%;
}
.collapsable .collapsable-inner {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 1px;
  padding-top: 80px;
}
.header-open .collapsable .collapsable-inner {
  background-color: #009fdf;
}
.primary-nav {
  text-align: left;
}
.primary-nav a {
  color: #fff;
}
.primary-nav a:hover {
  color: #fff;
}
.primary-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.primary-nav .nav-level .nav-level {
  padding-left: 20px;
}
.primary-nav .nav-group {
  margin: 0;
  position: relative;
}
.primary-nav .nav-group::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  content: "";
  left: 20px;
  line-height: 40px;
  position: absolute;
  top: 0;
}
.primary-nav .nav-group.active .nav-level-2 a,
.primary-nav .nav-group.current .nav-level-2 a {
  text-decoration: underline;
}
.primary-nav .nav-group.current .nav-level-2 {
  height: auto;
}
.primary-nav .nav-group.back {
  margin-top: 40px;
}
.primary-nav .nav-group.back::before {
  content: "";
}
.primary-nav .nav-group.active > .nav-sublevel {
  height: auto !important;
}
.primary-nav .nav-item,
.primary-nav .nav-item a {
  display: block;
}
.primary-nav .nav-item {
  margin: 0 20px;
}
.primary-nav .nav-item a {
  border-bottom: 1px solid #4cc2f1;
  border-top: 1px solid transparent;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  padding: 11px 0 11px 16px;
}
.primary-nav .nav-level2 > .nav-group > .nav-item {
  margin-right: 0;
}
.primary-nav .nav-sublevel {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 250ms ease-in-out;
  -o-transition: height 250ms ease-in-out;
  transition: height 250ms ease-in-out;
}
.primary-nav .tout-list {
  display: none;
}
.global-nav {
  margin: 10px 0;
  position: relative;
  text-align: left;
}
.global-nav .container {
  padding-left: 0;
  padding-right: 0;
}
.global-nav .global-nav-right,
.global-nav .global-nav-left {
  margin: 0;
  padding: 0;
}
.global-nav .global-nav-right div,
.global-nav .global-nav-left div {
  display: block;
  font-size: 14px;
  margin: 5px 0 0;
  position: relative;
}
.global-nav .global-nav-left {
  display: none;
}
.global-nav .global-nav-right {
  margin: 30px 20px;
}
#tab-log-in,
#tab-register,
#tab-contact-us {
  background-image: none;
  border-radius: 200px;
  border-style: solid;
  border-width: 2px;
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 10px 0;
  max-width: 100%;
  overflow: hidden;
  padding: 9px 25px;
  text-align: center;
  text-overflow: ellipsis;
  touch-action: manipulation;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
#tab-log-in {
  color: #7fba00;
  background-color: #fff;
  border-color: #fff;
}
#tab-register {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
#tab-contact-us {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
@media (min-width: 740px) {
  .header {
    text-align: left;
    z-index: auto;
  }
  .header nav {
    margin: 0;
  }
  .login,
  .menu {
    display: none;
    z-index: auto;
  }
  .brand {
    float: left;
    height: 90px;
    left: auto;
    margin: 25px 10px 25px;
    position: static;
    top: 0;
    width: 90px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .collapsable {
    height: auto;
    overflow: visible;
    padding-bottom: 0;
    position: relative;
    top: auto;
  }
  .collapsable .collapsable-inner {
    box-shadow: none;
    padding-bottom: 0;
    padding-top: 0;
  }
  .primary-nav a {
    color: #009fdf;
    float: left;
  }
  .primary-nav .nav-level .nav-level {
    padding-left: 0;
  }
  .primary-nav .nav-item {
    margin: 0;
  }
  .primary-nav .nav-item a {
    border: 0;
    font-size: 20px;
    padding: 20px 20px 18px;
  }
  .primary-nav .nav-group {
    position: relative;
  }
  .primary-nav .nav-group:hover {
    z-index: 3;
  }
  .primary-nav .nav-group::before {
    content: none;
  }
  .primary-nav .nav-group.current > .nav-item a {
    color: #000;
    text-decoration: none;
  }
  .primary-nav .nav-group.back {
    display: none;
  }
  .primary-nav .nav-level1 > .nav-group > .nav-item {
    display: none;
  }
  .primary-nav .nav-level2 {
    margin-left: 0;
    margin-top: 40px;
  }
  .primary-nav .nav-level2 .nav-group:hover > .nav-item a {
    background: #fff;
    border-radius: 5px 5px 0 0;
    color: #009fdf;
    position: relative;
    text-decoration: none;
    z-index: 3;
  }
  .primary-nav .nav-level2 .nav-group:hover > .nav-sublevel {
    display: block;
  }
  .primary-nav .nav-level2 .nav-group:hover.current > .nav-item a {
    color: #000;
  }
  .primary-nav .nav-level3 {
    height: auto;
  }
  .primary-nav .nav-level3 .nav-item a {
    width: 100%;
  }
  .primary-nav .nav-sublevel {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.2);
    display: none;
    height: auto;
    left: 0;
    padding: 20px;
    position: absolute;
    top: 58px;
    width: 100%;
  }
  .primary-nav .nav-level3 {
    float: left;
    padding: 0;
    width: 50%;
  }
  .primary-nav .nav-level3 .nav-group .nav-item {
    padding: 0 20px 0 0;
  }
  .primary-nav .nav-level3 .nav-group a {
    border-bottom: 1px solid #7fba00;
    color: #71857e;
    font-size: 16px;
    line-height: 1;
    padding: 10px 0;
  }
  .primary-nav .nav-level3 .nav-group a::before {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #7fba00;
    content: "";
    font-size: 15px;
    margin-right: 10px;
  }
  .primary-nav .tout-list {
    display: block;
    float: left;
    margin: 0;
    width: 50%;
  }
  .primary-nav .tout-list .tout {
    margin: 0 0 10px;
    padding: 13px;
  }
  .primary-nav .tout-list .tout:hover {
    border-color: #abbcb6;
  }
  .primary-nav .tout-list .tout:hover span {
    color: #4d8504;
  }
  .primary-nav .tout-list .tout:last-child {
    margin-bottom: 0;
  }
  .primary-nav .tout-list .tout-inner {
    display: table;
    float: none;
  }
  .primary-nav .tout-list .tout-inner:hover {
    text-decoration: none;
  }
  .primary-nav .tout-list .tout-content,
  .primary-nav .tout-list .tout-image {
    vertical-align: middle;
  }
  .primary-nav .tout-list .tout-content {
    display: table-cell;
  }
  .primary-nav .tout-list .tout-link {
    margin: 0;
  }
  .primary-nav .tout-list .tout-image {
    display: inline-block;
    float: left;
    margin: 0 10px 0 0;
    height: 50px;
    width: 50px;
  }
  .global-nav {
    background-color: #dae6e2;
    border: 0;
    display: block;
    font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 40px;
    margin: 0;
    min-height: 40px;
  }
  .global-nav a {
    border: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #009fdf;
    display: inline-block;
    line-height: 36px;
    margin: 0;
    padding: 0 10px;
    vertical-align: bottom;
  }
  .global-nav a:hover {
    color: #057dbc;
    text-decoration: none;
  }
  .global-nav a::before,
  .global-nav a::after {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 1;
    vertical-align: baseline;
  }
  .global-nav a::after {
    content: "";
    margin-left: 5px;
  }
  .global-nav .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .global-nav .global-nav-right {
    float: right;
    margin: 0;
  }
  .global-nav .global-nav-left {
    display: block;
    float: left;
  }
  .global-nav .global-nav-right,
  .global-nav .global-nav-left {
    height: 40px;
    line-height: 40px;
  }
  .global-nav .active {
    background-color: #fff;
    color: #000;
  }
  .global-nav .active::after {
    content: none;
  }
  .global-nav .active:hover {
    color: #000;
  }
  .global-nav .back::before {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    margin-right: 5px;
  }
  .global-nav .back::after {
    content: none;
    margin-left: 5px;
  }
  .global-nav .tab {
    color: #fff;
    margin-left: 4px;
  }
  #tab-log-in,
  #tab-register,
  #tab-contact-us {
    background: 0;
    border-radius: 5px 5px 0 0;
    border-width: 0;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    line-height: 36px;
    margin: 0;
    padding: 0 10px;
    text-align: left;
    vertical-align: bottom;
  }
  #tab-log-in:hover,
  #tab-register:hover,
  #tab-contact-us:hover {
    color: #fff;
  }
  #tab-log-in {
    background-color: #009fdf;
  }
  #tab-log-in::before {
    background-color: #fff;
    border-radius: 50%;
    content: "\f007";
    color: #009fdf;
    display: inline-block;
    font-family: "FontAwesome";
    font-size: 13px;
    height: 17px;
    margin-right: 5px;
    padding: 2px;
    text-align: center;
    text-indent: -1px;
    width: 17px;
  }
  #tab-register {
    background-color: #7fba00;
  }
  #tab-contact-us {
    background-color: #71857e;
  }
}
@media (min-width: 1020px) {
  .primary-nav .nav-item a {
    font-size: 22px;
  }
  .primary-nav .tout-list .tout-image {
    height: 60px;
    width: 60px;
  }
}
.header-fixed {
  text-align: left;
  z-index: auto;
}
.header-fixed nav {
  margin: 0;
}
.header-fixed .login,
.header-fixed .menu {
  display: none;
  z-index: auto;
}
.header-fixed .brand {
  float: left;
  height: 90px;
  left: auto;
  margin: 25px 10px 25px;
  position: static;
  top: 0;
  width: 90px;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
}
.header-fixed .collapsable {
  height: auto;
  overflow: visible;
  padding-bottom: 0;
  position: relative;
  top: auto;
}
.header-fixed .collapsable .collapsable-inner {
  box-shadow: none;
  padding-bottom: 0;
  padding-top: 0;
}
.header-fixed .primary-nav a {
  color: #009fdf;
  float: left;
}
.header-fixed .primary-nav .nav-level .nav-level {
  padding-left: 0;
}
.header-fixed .primary-nav .nav-item {
  margin: 0;
}
.header-fixed .primary-nav .nav-item a {
  border: 0;
  font-size: 20px;
  padding: 20px 20px 18px;
}
.header-fixed .primary-nav .nav-group {
  position: relative;
}
.header-fixed .primary-nav .nav-group:hover {
  z-index: 3;
}
.header-fixed .primary-nav .nav-group::before {
  content: none;
}
.header-fixed .primary-nav .nav-group.current > .nav-item a {
  color: #000;
  text-decoration: none;
}
.header-fixed .primary-nav .nav-group.back {
  display: none;
}
.header-fixed .primary-nav .nav-level1 > .nav-group > .nav-item {
  display: none;
}
.header-fixed .primary-nav .nav-level2 {
  margin-left: 0;
  margin-top: 40px;
}
.header-fixed .primary-nav .nav-level2 .nav-group:hover > .nav-item a {
  background: #fff;
  border-radius: 5px 5px 0 0;
  color: #009fdf;
  position: relative;
  text-decoration: none;
  z-index: 3;
}
.header-fixed .primary-nav .nav-level2 .nav-group:hover > .nav-sublevel {
  display: block;
}
.header-fixed .primary-nav .nav-level2 .nav-group:hover.current > .nav-item a {
  color: #000;
}
.header-fixed .primary-nav .nav-level3 {
  height: auto;
}
.header-fixed .primary-nav .nav-level3 .nav-item a {
  width: 100%;
}
.header-fixed .primary-nav .nav-sublevel {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.2);
  display: none;
  height: auto;
  left: 0;
  padding: 20px;
  position: absolute;
  top: 58px;
  width: 100%;
}
.header-fixed .primary-nav .nav-level3 {
  float: left;
  padding: 0;
  width: 50%;
}
.header-fixed .primary-nav .nav-level3 .nav-group .nav-item {
  padding: 0 20px 0 0;
}
.header-fixed .primary-nav .nav-level3 .nav-group a {
  border-bottom: 1px solid #7fba00;
  color: #71857e;
  font-size: 16px;
  line-height: 1;
  padding: 10px 0;
}
.header-fixed .primary-nav .nav-level3 .nav-group a::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7fba00;
  content: "";
  font-size: 15px;
  margin-right: 10px;
}
.header-fixed .primary-nav .tout-list {
  display: block;
  float: left;
  margin: 0;
  width: 50%;
}
.header-fixed .primary-nav .tout-list .tout {
  margin: 0 0 10px;
  padding: 13px;
}
.header-fixed .primary-nav .tout-list .tout:hover {
  border-color: #abbcb6;
}
.header-fixed .primary-nav .tout-list .tout:hover span {
  color: #4d8504;
}
.header-fixed .primary-nav .tout-list .tout:last-child {
  margin-bottom: 0;
}
.header-fixed .primary-nav .tout-list .tout-inner {
  display: table;
  float: none;
}
.header-fixed .primary-nav .tout-list .tout-inner:hover {
  text-decoration: none;
}
.header-fixed .primary-nav .tout-list .tout-content,
.header-fixed .primary-nav .tout-list .tout-image {
  vertical-align: middle;
}
.header-fixed .primary-nav .tout-list .tout-content {
  display: table-cell;
}
.header-fixed .primary-nav .tout-list .tout-link {
  margin: 0;
}
.header-fixed .primary-nav .tout-list .tout-image {
  display: inline-block;
  float: left;
  margin: 0 10px 0 0;
  height: 50px;
  width: 50px;
}
.header-fixed .primary-nav .nav-item a {
  font-size: 22px;
}
.header-fixed .primary-nav .tout-list .tout-image {
  height: 60px;
  width: 60px;
}
.header-fixed .global-nav {
  background-color: #dae6e2;
  border: 0;
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 40px;
  margin: 0;
  min-height: 40px;
}
.header-fixed .global-nav a {
  border: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #009fdf;
  display: inline-block;
  line-height: 36px;
  margin: 0;
  padding: 0 10px;
  vertical-align: bottom;
}
.header-fixed .global-nav a:hover {
  color: #057dbc;
  text-decoration: none;
}
.header-fixed .global-nav a::before,
.header-fixed .global-nav a::after {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1;
  vertical-align: baseline;
}
.header-fixed .global-nav a::after {
  content: "";
  margin-left: 5px;
}
.header-fixed .global-nav .container {
  padding-left: 10px;
  padding-right: 10px;
}
.header-fixed .global-nav .global-nav-right {
  float: right;
  margin: 0;
}
.header-fixed .global-nav .global-nav-left {
  display: block;
  float: left;
}
.header-fixed .global-nav .global-nav-right,
.header-fixed .global-nav .global-nav-left {
  height: 40px;
  line-height: 40px;
}
.header-fixed .global-nav .active {
  background-color: #fff;
  color: #000;
}
.header-fixed .global-nav .active::after {
  content: none;
}
.header-fixed .global-nav .active:hover {
  color: #000;
}
.header-fixed .global-nav .back::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  margin-right: 5px;
}
.header-fixed .global-nav .back::after {
  content: none;
  margin-left: 5px;
}
.header-fixed .global-nav .tab {
  color: #fff;
  margin-left: 4px;
}
.header-fixed #tab-log-in,
.header-fixed #tab-register {
  background: 0;
  border-radius: 5px 5px 0 0;
  border-width: 0;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  line-height: 36px;
  margin: 0;
  padding: 0 10px;
  text-align: left;
  vertical-align: bottom;
}
.header-fixed #tab-log-in:hover,
.header-fixed #tab-register:hover {
  color: #fff;
}
.header-fixed #tab-log-in {
  background-color: #009fdf;
}
.header-fixed #tab-log-in::before {
  background-color: #fff;
  border-radius: 50%;
  content: "\f007";
  color: #009fdf;
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 13px;
  height: 17px;
  margin-right: 5px;
  padding: 2px;
  text-align: center;
  text-indent: -1px;
  width: 17px;
}
.header-fixed #tab-register {
  background-color: #7fba00;
}
.header-fixed .container {
  width: 960px;
}
.search[role="search"] {
  border: 0;
  margin-right: 0;
  padding: 0 20px;
  position: relative;
}
.search-group {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  -webkit-transition: border-color 250ms ease-in-out;
  -o-transition: border-color 250ms ease-in-out;
  transition: border-color 250ms ease-in-out;
}
.search-group .search-label {
  display: none;
  height: 34px;
  margin: 0;
  position: absolute;
  right: 0;
  width: 34px;
  z-index: 2;
}
.search-open .search-group .search-label {
  z-index: 0;
}
.search-group input[type="search"] {
  background: 0;
  border: 0;
  box-shadow: none;
  color: #555;
  height: 100%;
  left: 0;
  padding: 1px 38px 0 8px;
  position: absolute;
}
.search-group button[type="submit"],
.search-group button[type="reset"] {
  background-color: #7fba00;
  border: 0;
  color: #fff;
  z-index: 1;
}
.search-group button[type="submit"]::before,
.search-group button[type="reset"]::before {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.search-group button[type="submit"] {
  border-radius: 25px;
  display: block;
  float: right;
  font-size: 18px;
  height: 34px;
  margin: 2px;
  padding: 15px;
  position: relative;
  width: 34px;
}
.search-group button[type="reset"] {
  border-radius: 10px;
  display: none;
  font-size: 10px;
  height: 20px;
  margin: 11px 9px;
  opacity: 0;
  padding: 2px 10px;
  position: absolute;
  right: 0;
  top: 0;
  will-change: opacity;
  z-index: 0;
  -webkit-transition: opacity 250ms ease-in-out;
  -o-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.search-autocomplete {
  display: none;
}
.header-fixed .search[role="search"] {
  background: #fff;
  padding: 10px 10px 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.header-fixed .search-group {
  border-color: #dae6e2;
  border-width: 2px;
  position: relative;
  width: 44px;
  will-change: width;
  -webkit-transition: width 250ms ease-in-out, border-color 250ms ease-in-out;
  -o-transition: width 250ms ease-in-out, border-color 250ms ease-in-out;
  transition: width 250ms ease-in-out, border-color 250ms ease-in-out;
}
.header-fixed .search-group .search-label {
  display: block;
  height: 36px;
  width: 36px;
}
.header-fixed .search-group input[type="search"] {
  padding: 0;
  visibility: hidden;
}
.header-fixed .search-group button[type="submit"] {
  float: none;
  margin: 3px;
}
.header-fixed .search-group button[type="reset"] {
  display: block;
}
.header-fixed .search-open .search-group {
  border-color: #009fdf;
  width: 580px;
}
.header-fixed .search-open input[type="search"] {
  padding: 1px 72px 0 44px;
  visibility: visible;
}
.header-fixed .search-open button[type="reset"] {
  opacity: 1;
  z-index: 1;
}
.header-fixed .search-open .search-group {
  width: 810px;
}
@media (min-width: 740px) {
  .search[role="search"] {
    background: #fff;
    padding: 10px 10px 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .search-group {
    border-color: #dae6e2;
    border-width: 2px;
    position: relative;
    width: 44px;
    will-change: width;
    -webkit-transition: width 250ms ease-in-out, border-color 250ms ease-in-out;
    -o-transition: width 250ms ease-in-out, border-color 250ms ease-in-out;
    transition: width 250ms ease-in-out, border-color 250ms ease-in-out;
  }
  .search-group .search-label {
    display: block;
    height: 36px;
    width: 36px;
  }
  .search-group input[type="search"] {
    padding: 0;
    visibility: hidden;
  }
  .search-group button[type="submit"] {
    float: none;
    margin: 3px;
  }
  .search-group button[type="reset"] {
    display: block;
  }
  .search-open .search-group {
    border-color: #009fdf;
    width: 580px;
  }
  .search-open input[type="search"] {
    padding: 1px 72px 0 44px;
    visibility: visible;
  }
  .search-open button[type="reset"] {
    opacity: 1;
    z-index: 1;
  }
}
@media (min-width: 1020px) {
  .search-open .search-group {
    width: 810px;
  }
}
@media (min-width: 1260px) {
  .search-open .search-group {
    width: 1050px;
  }
}
@media (max-width: 1028px) {
  .contact li.option-item {
    height: auto !important;
    width: 100% !important;
    padding: 0.1%;
    list-style: none;
  }
}
.tout {
  border: 2px solid #dae6e2;
  border-radius: 5px;
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 12px;
}
.tout p {
  clear: both;
  line-height: 22px;
  margin-bottom: 16px;
}
.tout p:last-of-type {
  padding-bottom: 0;
}
.tout ul {
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 16px;
  list-style: none;
  margin-left: 0 !important;
  text-indent: 0 !important;
}
.tout ul li {
  border-bottom: 1px solid #4cc2f1;
  position: relative;
}
.tout ul li::before {
  display: none;
}
.tout ul a {
  display: block;
  padding: 10px 0 8px 18px;
}
.tout ul a::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 15px;
}
.tout ul + ul {
  margin-bottom: 16px;
  margin-top: 0;
}
.tout .tout-content {
  width: 100%;
}
.tout .tout-title {
  margin-bottom: 12px;
}
.tout .tout-title + p {
  margin-top: 0;
}
.tout .tout-image {
  float: right;
  height: 60px;
  margin: 0 0 12px 12px;
  width: 60px;
}
.tout .tout-link {
  display: block;
  margin-bottom: 0;
}
.tout .tout-link + .tout-link,
.tout .btn + .btn {
  margin-top: 15px;
}
.tout .tout-link + .tout-link {
  border-top: 0;
  padding-top: 0;
}
.tout .search-mini {
  border: 0;
  padding: 0;
}
.tout .search-mini .form-group {
  border: 2px solid #dae6e2;
  border-radius: 20px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.tout .search-mini .form-group.has-focus {
  border-color: #009fdf;
}
.tout .search-mini input[type="search"],
.tout .search-mini input[type="text"] {
  border: 0;
  box-shadow: none;
  height: 36px;
  padding: 6px 45px 6px 10px;
}
.tout .search-mini button[type="submit"] {
  background: #7fba00;
  border: 0;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 30px;
  z-index: 1;
}
.tout .search-mini button[type="submit"]:hover {
  background: #4d8504;
}
.tout .search-mini span.c-tw-blue {
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2em;
  margin: 20px 0;
  text-align: center;
}
.tout .search-mini .btn-sm,
.tout .search-mini .btn-group-sm > .btn {
  margin: 20px 0;
  width: 100%;
}
.tout.bg-tw-blue {
  border-color: #009fdf;
}
.tout.bg-tw-blue .tout-title,
.tout.bg-tw-blue p,
.tout.bg-tw-blue li a {
  color: #fff;
}
.tout.bg-tw-blue .form-group {
  background-color: #fff;
  border: 0;
}
.tout.bg-tw-green-tint {
  border-color: #ecf5d9;
}
.tout.bg-tw-green-tint .form-group {
  background-color: #fff;
  border: 0;
}
.tout.tout-featured {
  border-color: #009fdf;
}
.tout.text {
  border-color: transparent;
  border-bottom-color: #7fba00;
  border-bottom-width: 1px;
  border-radius: 0;
  padding: 15px 0;
}
.tout.text .tout-title {
  margin-bottom: 15px;
}
.flexbox .tout.text .tout-inner {
  height: 100%;
}
.flexbox .tout.text .tout-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.flexbox .tout.text .tout-link {
  margin-top: auto;
}
.tout.promotional {
  text-align: center;
}
.tout.promotional p {
  font-size: 16px;
}
.tout.promotional .tout-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.tout.promotional .tout-image {
  height: 136px;
  margin: 0 auto 20px;
  width: 136px;
}
.tout.promotional .tout-content {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.tout.promotional .tout-title {
  margin-bottom: 16px;
  margin-top: 0;
}
.tout.promotional .btn {
  margin: 24px 0 15px;
}
.tout.primary {
  overflow: hidden;
}
.tout.primary p {
  position: absolute;
  visibility: hidden;
}
.tout.primary.primary-contact p {
  position: static;
  visibility: visible;
}
.tout.primary .tout-image {
  margin-bottom: 0;
}
.tout.primary .tout-title {
  margin-bottom: 10px;
  margin-top: 0;
}
.tout.title-centered .tout-inner,
.tout.primary-medium .tout-inner {
  display: table;
  min-height: 60px;
  padding-right: 72px;
  position: relative;
  width: 100%;
}
.tout.title-centered .tout-image,
.tout.primary-medium .tout-image {
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 0;
}
.tout.title-centered .tout-content,
.tout.primary-medium .tout-content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.tout.title-centered .tout-title,
.tout.primary-medium .tout-title {
  margin: 0;
}
.tout.primary-medium.photo {
  padding: 0;
  text-align: left;
}
.tout.primary-medium.photo .tout-inner {
  padding-right: 31%;
  height: 172px;
}
.tout.primary-medium.photo .tout-inner .tout-image {
  height: 100%;
  position: absolute;
  top: 0;
  background-position: 1px 50%;
  width: 31%;
}
.tout.primary-medium.photo .tout-inner .tout-title {
  margin-bottom: 20px;
}
.tout.primary-medium.photo .tout-inner .tout-content {
  padding: 20px 12px;
}
.tout.primary-medium.photo.bg-tw-white .tout-image::before {
  background-image: url(../images/carousel-photomask--white--vert.png);
  background-position: left;
  background-size: auto 102%;
}
.tout.primary-medium.photo.bg-tw-blue .tout-image::before {
  background-image: url(../images/carousel-photomask--blue--vert.png);
  background-position: left;
  background-size: auto 102%;
}
.tout.primary-medium.photo.bg-tw-blue:hover {
  color: #fff;
}
.tout.photo {
  border: 0;
  text-align: center;
  overflow: hidden;
  padding: 0;
}
.tout.photo .tout-content {
  padding: 30px 30px 10px;
  position: relative;
  z-index: 2;
}
.tout.photo .tout-image {
  background-position: 50% 1px;
  background-repeat: no-repeat;
  background-size: cover;
  float: none;
  height: 270px;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.tout.photo .tout-image::before {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 102% auto;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  padding-top: 54px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.tout.photo.bg-tw-white .tout-image::before {
  background-image: url(../images/carousel-photomask--white--horiz.png);
}
.tout.photo.bg-tw-blue .tout-image::before {
  background-image: url(../images/carousel-photomask--blue--horiz.png);
}
.tout.photo .tout-title {
  margin-top: 0;
}
.tout.search .tout-title {
  margin-bottom: 26px;
  padding-right: 72px;
}
.tout.option {
  border: 0;
  margin-bottom: 0;
  padding: 8px;
}
.tout.option .tout-inner {
  display: table;
  text-align: left;
}
.tout.option .tout-image,
.tout.option .tout-content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.tout.option .tout-image {
  float: none;
  height: 50px;
  margin: 0 12px 0 0;
  width: 50px;
}
.tout.option .tout-title {
  color: #000;
  margin: 0;
}
.tout.ask .search-mini {
  display: block;
}
.tout.primarytout-small {
  padding: 0;
}
.tout.primarytout-small .tout-inner {
  padding: 12px;
  display: table;
  height: 100%;
}
.tout.primarytout-small .tout-body,
.tout.primarytout-small .tout-right {
  display: table-cell;
  vertical-align: middle;
}
.tout.primarytout-small .tout-body {
  width: 100000px;
}
.tout.primarytout-small .tout-body .tout-title {
  margin: 0;
  font-size: 20px;
}
.tout.primarytout-small .tout-right {
  padding-left: 22px;
}
.tout.primarytout-small .tout-right .tout-image {
  height: 60px;
  width: 60px;
  margin: 0;
}
.tout.primarytout-small.photo .tout-inner {
  padding: 0;
}
.tout.primarytout-small.photo .tout-body {
  padding: 12px 0 12px 12px;
}
.tout.primarytout-small.photo .tout-title {
  text-align: left;
}
.tout.primarytout-small.photo .tout-right {
  padding-left: 98px;
  position: relative;
  height: 88px;
}
.tout.primarytout-small.photo .tout-right .tout-image {
  min-height: 84px;
  width: 84px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.tout.primarytout-small.photo.bg-tw-white .tout-image::before {
  background-image: url(../images/carousel-photomask--white--vert.png);
  background-position: left;
  background-size: auto 102%;
}
.tout.primarytout-small.photo.bg-tw-blue .tout-image::before {
  background-image: url(../images/carousel-photomask--blue--vert.png);
  background-position: left;
  background-size: auto 102%;
}
.tout.primarytout-small.bg-tw-blue:hover {
  color: #fff;
}
.options {
  border: 2px solid #dae6e2;
  border-radius: 5px;
  margin-bottom: 20px;
}
.options.get-in-touch {
  margin-bottom: 0;
}
.options:hover {
  border-color: #abbcb6;
}
.options:focus,
.options.open {
  border-color: #7fba00;
}
.options.open .option-button::after {
  content: "\f139";
}
.options .option-button,
.options .option-item,
.options .option {
  cursor: pointer;
}
.options .option-button {
  background: 0;
  border: 0;
  padding: 0;
  position: relative;
}
.options .option-button::after {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f13a";
  color: #7fba00;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.options .option-list-wrapper {
  height: 0;
  overflow: hidden;
}
.options .option-item {
  border-top: 2px solid #dae6e2;
}
.contact li.option-item {
  height: 330px;
  width: 23%;
  padding: 0.25%;
}
@media (min-width: 740px) {
  .tout {
    margin-bottom: 20px;
    padding: 20px;
  }
  .tout p {
    clear: none;
  }
  .tout .tout-image {
    height: 100px;
    margin-left: 40px;
    width: 100px;
  }
  .tout .tout-title {
    margin-bottom: 20px;
  }
  .tout.promotional {
    padding: 40px;
    text-align: left;
  }
  .tout.promotional .tout-inner {
    display: table;
    overflow: hidden;
    width: 100%;
  }
  .tout.promotional .tout-image {
    float: right;
    height: 258px;
    margin-left: 40px;
    margin-bottom: 0;
    width: 258px;
  }
  .tout.promotional .tout-content {
    display: table-cell;
    vertical-align: middle;
  }
  .tout.promotional .btn {
    margin: 10px 20px 0 0;
  }
  .tout.promotional.promotional-small {
    padding: 30px;
  }
  .tout.promotional.promotional-small .tout-image {
    height: 180px;
    margin-left: 40px;
    width: 180px;
  }
  .tout.primary {
    text-align: center;
  }
  .tout.primary .tout-image {
    float: none;
    height: 120px;
    margin-bottom: 25px;
    margin-left: 0;
    width: 120px;
  }
  .tout.primary p {
    position: static;
    visibility: visible;
  }
  .tout.title-centered {
    text-align: center;
  }
  .tout.title-centered .tout-inner {
    display: block;
    min-height: 0;
    padding-right: 0;
    position: static;
  }
  .tout.title-centered .tout-image {
    float: none;
    height: 120px;
    margin-bottom: 30px;
    margin-left: 0;
    position: static;
    right: auto;
    top: auto;
    width: 120px;
  }
  .tout.title-centered .tout-content {
    display: block;
  }
  .tout.primary-medium .tout-inner .tout-title {
    font-size: 20px;
  }
  .tout.primary-medium.photo .tout-inner {
    padding-right: 60%;
  }
  .tout.primary-medium.photo .tout-inner .tout-image {
    width: 60%;
  }
  .tout.primary-medium.photo .tout-inner .tout-content {
    padding: 30px 20px;
  }
  .tout.ask .tout-content {
    margin-left: -10px;
    margin-right: -10px;
  }
  .tout.ask .tout-content:before,
  .tout.ask .tout-content:after {
    content: " ";
    display: table;
  }
  .tout.ask .tout-content:after {
    clear: both;
  }
  .tout.ask .tout-title {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
    margin-top: 6px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .tout.ask .tout-title {
    float: left;
    width: 33.33333%;
  }
}
@media (min-width: 740px) {
  .tout.ask .search-mini {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .tout.ask .search-mini {
    float: left;
    width: 66.66667%;
  }
}
@media (min-width: 740px) {
  .tout.help .tout-title {
    margin-top: 0;
  }
  .tout.help ul {
    margin-bottom: 10px;
  }
  .tout.help.help-wide .tout-content {
    margin-left: -10px;
    margin-right: -10px;
  }
  .tout.help.help-wide .tout-content:before,
  .tout.help.help-wide .tout-content:after {
    content: " ";
    display: table;
  }
  .tout.help.help-wide .tout-content:after {
    clear: both;
  }
  .tout.help.help-wide .tout-title,
  .tout.help.help-wide .search-mini {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .tout.help.help-wide .tout-title,
  .tout.help.help-wide .search-mini {
    float: left;
    width: 50%;
  }
}
@media (min-width: 740px) {
  .tout.help.help-wide .tout-title {
    margin-bottom: 8px;
    margin-top: 0;
  }
  .tout.help.help-wide ul {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 10px !important;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .tout.help.help-wide ul {
    float: left;
    width: 50%;
  }
}
@media (min-width: 740px) {
  .tout.photo {
    text-align: left;
  }
  .tout.photo .tout-inner {
    display: table;
    overflow: hidden;
    width: 100%;
  }
  .tout.photo .tout-content {
    display: table-cell;
    padding: 20px;
    vertical-align: top;
  }
  .tout.photo .tout-image {
    background-position: 1px 50%;
    float: right;
    height: 360px;
    margin-top: 0;
    width: 420px;
  }
  .tout.photo .tout-image::before {
    background-position: left;
    background-size: auto 102%;
  }
  .tout.photo .btn {
    margin: 10px 20px 0 0;
  }
  .tout.photo.bg-tw-white .tout-image::before {
    background-image: url(../images/carousel-photomask--white--vert.png);
  }
  .tout.photo.bg-tw-blue .tout-image::before {
    background-image: url(../images/carousel-photomask--blue--vert.png);
  }
  .tout.search .tout-content {
    width: 84%;
  }
  .tout.search .tout-title {
    padding-right: 0;
  }
  .tout.search .search-mini {
    display: inline-block;
  }
  .tout.search .search-mini .form-group {
    display: inline-block;
    vertical-align: middle;
  }
  .tout.search .search-mini span.c-tw-blue {
    display: inline-block;
    margin: 0 20px;
  }
  .tout.search .search-mini .btn-sm,
  .tout.search .search-mini .btn-group-sm > .btn {
    display: inline-block;
    margin: 0;
    width: auto;
  }
  .col-sm-12 .tout.photo.primarytout-small .tout-right {
    padding-left: 192px;
  }
  .col-sm-12 .tout.photo.primarytout-small .tout-right .tout-image {
    width: 180px;
  }
  .option-list {
    margin: 0;
  }
  .data-equalize .tout,
  .data-equalize .tout.photo .tout-image {
    min-height: 300px;
  }
}
@media (min-width: 1020px) {
  .sidebar .tout {
    padding: 20px;
    text-align: center;
  }
  .sidebar .tout .tout-image {
    display: inline-block;
    float: none;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .sidebar .tout.ask {
    text-align: left;
  }
  .sidebar .tout.ask .tout-content {
    margin: 0;
  }
  .sidebar .tout.ask .tout-title {
    float: none;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 0;
    width: auto;
  }
  .sidebar .tout.ask .search-mini {
    float: none;
    padding: 0;
    width: auto;
  }
  .tout.promotional .tout-title {
    font-size: 32px;
  }
  .tout.promotional-fullwidth {
    margin-left: 0;
    margin-right: 0;
    padding: 8px;
  }
  .tout.promotional-fullwidth .tout-inner {
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 83.05084%;
  }
  .tout.promotional-fullwidth .tout-content {
    width: 50%;
  }
  .tout.promotional-fullwidth .tout-image {
    height: 340px;
    margin-bottom: 37px;
    margin-top: 37px;
    width: 340px;
  }
  .tout.primary-medium.photo .tout-inner {
    padding-right: 70%;
  }
  .tout.primary-medium.photo .tout-inner .tout-image {
    width: 70%;
  }
  .tout.photo .tout-title {
    font-size: 32px;
  }
  .tout.photo .tout-image {
    width: 634px;
  }
  .tout.option {
    border: 2px solid #dae6e2;
    height: 100%;
    -webkit-transition: background 250ms ease-in-out, border-color 250ms ease-in-out;
    -o-transition: background 250ms ease-in-out, border-color 250ms ease-in-out;
    transition: background 250ms ease-in-out, border-color 250ms ease-in-out;
  }
  .tout.option:focus,
  .tout.option:hover {
    border-color: #abbcb6;
  }
  .tout.option .tout-inner {
    display: block;
    text-align: center;
  }
  .tout.option .tout-image,
  .tout.option .tout-content {
    display: block;
    height: auto;
  }
  .tout.option .tout-image {
    display: inline-block;
    float: none;
    height: 100px;
    margin: 0 0 12px;
    width: 100px;
  }
  .tout.option.current {
    border-color: #009fdf;
    background-color: #009fdf;
    display: block;
  }
  .tout.option.current .tout-title {
    color: #fff;
  }
  .tools-template .tout.primary-medium.photo .tout-inner {
    padding-right: 65%;
  }
  .tools-template .tout.primary-medium.photo .tout-inner .tout-image {
    width: 65%;
  }
  .options {
    border: 0;
  }
  .options .option-button {
    display: none;
  }
  .options .option-list-wrapper {
    height: auto;
  }
  .options .option-list {
    width: 100%;
  }
  .options .option-item {
    border: 0;
    display: block;
    float: left;
    margin: 0 10px 20px 0;
    width: 166px;
  }
  .options .option-item:last-child {
    padding-right: 0;
  }
  .options .option {
    padding: 20px 30px;
  }
  .options .option .tout-title {
    color: #009fdf;
    font-size: 16px;
    line-height: 1.5;
  }
  .options .option:hover .tout-title {
    text-decoration: underline;
  }
}
@media (min-width: 1260px) {
  .tout.promotional-fullwidth .tout-title {
    font-size: 40px;
  }
  .tout.promotional-fullwidth .tout-image {
    height: 375px;
    width: 375px;
  }
  .tout.primary-medium.photo .tout-inner {
    padding-right: 75%;
  }
  .tout.primary-medium.photo .tout-inner .tout-image {
    width: 75%;
  }
  .tout.photo .tout-image {
    height: 466px;
    width: 820px;
  }
  .tout.option {
    padding: 28px;
  }
  .options .option-item {
    margin-right: 2px;
  }
}
.footer {
  border-top: 2px solid #007eb1;
  background-color: #009fdf;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  margin-top: 40px;
  padding: 0 30px;
  text-align: center;
}
.footer h4,
.footer p,
.footer a {
  color: #fff;
}
.footer p {
  font-size: 16px;
}
.footer a:hover {
  color: #fff;
}
.footer ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.footer ul > li::before {
  content: none;
}
.footer li {
  display: inline-block;
  margin-right: 16px;
}
.footer li:last-child {
  margin-right: 0;
}
.footer .helpful-links,
.footer .footer-brand,
.footer .social-badges {
  margin: 30px 0;
}
.footer .helpful-links {
  background: 0;
  border-color: #4cc2f1;
  border-width: 2px;
  overflow: hidden;
  text-align: left;
  -webkit-transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.footer .helpful-links:hover {
  border-color: #d9f1fa;
}
.footer .helpful-links h4 {
  font-size: 16px;
  line-height: 1.25;
  margin: 0;
}
.footer .helpful-links h4 button {
  border: 0;
  background: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  text-align: left;
  width: 100%;
}
.footer .helpful-links h4 i.fa {
  background-color: #fff;
  border-radius: 50%;
  color: #7fba00;
  float: right;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px;
}
.footer .helpful-links-content {
  height: 0;
  overflow: hidden;
}
.footer .helpful-links-content ul {
  margin: 0 10px;
  text-indent: 0 !important;
  margin-left: 10px !important;
}
.footer .helpful-links-content ul:last-child {
  margin-top: 10px;
}
.footer .helpful-links-content li {
  display: block;
  line-height: 32px;
  width: 100%;
}
.footer .helpful-links-content a {
  display: block;
}
.footer .helpful-links-content a::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  margin-right: 8px;
}
.footer .footer-brand {
  display: block;
  text-align: center;
}
.footer .footer-brand img {
  display: inline-block;
  height: 80px;
  width: 80px;
}
.footer .copyright {
  margin-bottom: 38px;
}
.footer .social-badge {
  background-color: #fff;
  border-radius: 50%;
  color: #009fdf;
  display: table;
  height: 40px;
  width: 40px;
}
.footer .social-badge i {
  display: table-cell;
  font-size: 20px;
  vertical-align: middle;
}
.footer .social-badge:hover {
  color: #009fdf;
  text-decoration: none;
}
.footer ul > li::before {
  display: none;
}
@media (min-width: 740px) {
  .footer {
    padding: 0;
  }
  .footer .btn-tw-high-contrast {
    float: left;
  }
  .footer .helpful-links {
    border: 0;
    clear: left;
    overflow: visible;
    padding: 0 9%;
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .footer .helpful-links ul {
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
  .footer .helpful-links li {
    display: inline-block;
    width: auto;
  }
  .footer .helpful-links a {
    display: inline-block;
  }
  .footer .helpful-links a::before {
    content: none;
  }
  .footer .helpful-links-content {
    height: auto;
    overflow: visible;
  }
  .footer .footer-brand img {
    height: 90px;
    width: 90px;
  }
}
@media (min-width: 1020px) {
  .footer {
    margin-top: 86px;
  }
  .footer .footer-brand,
  .footer .social-badges {
    margin: 40px 0;
  }
}
.footer.footer-fixed {
  padding: 0;
  margin-top: 86px;
}
.footer.footer-fixed .btn-tw-high-contrast {
  float: left;
}
.footer.footer-fixed .helpful-links {
  border: 0;
  clear: left;
  overflow: visible;
  padding: 0 9%;
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer.footer-fixed .helpful-links ul {
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0 !important;
}
.footer.footer-fixed .helpful-links li {
  display: inline-block;
  width: auto;
}
.footer.footer-fixed .helpful-links a {
  display: inline-block;
}
.footer.footer-fixed .helpful-links a::before {
  content: none;
}
.footer.footer-fixed .helpful-links-content {
  height: auto;
  overflow: visible;
}
.footer.footer-fixed .footer-brand img {
  height: 90px;
  width: 90px;
}
.footer.footer-fixed .footer-brand,
.footer.footer-fixed .social-badges {
  margin: 40px 0;
}
.footer.footer-fixed .container {
  width: 960px;
}
.alert {
  border-width: 2px;
  position: relative;
}
.alert::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 14px;
  height: 20px;
  left: 13px;
  line-height: 1.2;
  margin-right: 11px;
  padding: 2px;
  position: absolute;
  top: 13px;
  text-align: center;
  width: 20px;
}
.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
  padding-left: 44px;
}
.alert-success::before {
  background-color: #7fba00;
  content: "";
}
.alert-info::before {
  background-color: #009fdf;
  content: "";
}
.alert-warning::before {
  background-color: #e8b600;
  content: "";
}
.alert-danger::before {
  background-color: $red;
  content: "";
}
.alert h2,
.alert h3,
.alert h4,
.alert p,
.alert ul,
.alert ol {
  color: #000;
  margin-top: 0;
}
.alert *:last-child {
  margin-bottom: 0;
}
.alert .close {
  font-size: 18px;
}
.accordions {
  margin: 20px 0;
}
.accordions .panel {
  border-color: #009fdf;
  overflow: hidden;
}
.accordions .panel-heading {
  border-radius: 0;
  padding: 0;
}
.accordions .panel-title {
  background-color: #fff;
  color: #009fdf;
  line-height: 1.6;
  -webkit-transition: color 250ms cubic-bezier(0, 0, 0.2, 1), background-color 250ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: color 250ms cubic-bezier(0, 0, 0.2, 1), background-color 250ms cubic-bezier(0, 0, 0.2, 1);
  transition: color 250ms cubic-bezier(0, 0, 0.2, 1), background-color 250ms cubic-bezier(0, 0, 0.2, 1);
}
.accordions .panel-title > span {
  display: block;
  padding: 12px 8px 12px 10px;
  position: relative;
}
.accordions .panel-title > span:hover,
.accordions .panel-title > span:focus {
  text-decoration: none;
}
.accordions .panel-title > span::before {
  background-color: #7fba00;
  border-radius: 50%;
  color: #fff;
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  float: right;
  height: 20px;
  line-height: 21px;
  margin-left: 10px;
  position: relative;
  text-align: center;
  top: 2px;
  width: 20px;
  -webkit-transition: color 250ms cubic-bezier(0, 0, 0.2, 1), background-color 250ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: color 250ms cubic-bezier(0, 0, 0.2, 1), background-color 250ms cubic-bezier(0, 0, 0.2, 1);
  transition: color 250ms cubic-bezier(0, 0, 0.2, 1), background-color 250ms cubic-bezier(0, 0, 0.2, 1);
}
.accordions .panel-title > span .tw {
  display: block;
  float: left;
  font-size: 20px;
  margin-right: 10px;
  position: relative;
  top: -1px;
}
.accordions .panel-title.collapsed {
  background-color: #009fdf;
  color: #fff;
}
.accordions .panel-title.collapsed > span::before {
  background-color: #fff;
  content: "";
  color: #7fba00;
}
.accordions .panel-body {
  padding-bottom: 0;
  padding-top: 0;
}
.accordions .panel-body > *:first-child {
  margin-top: 0;
}
.accordions .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}
@media (min-width: 740px) {
  .accordions {
    margin: 30px 0;
  }
  .accordions .panel-title > span {
    padding: 13px 10px 13px 13px;
  }
  .accordions .panel-title > span::before {
    margin-left: 15px;
  }
  .accordions .panel-title > span .tw {
    margin-right: 15px;
  }
}
.article-list {
  margin: 20px 0;
}
.article-list article {
  border-top: 1px solid #7fba00;
  padding: 20px 0;
  text-align: center;
}
.article-list article:first-child {
  border-top-color: #dae6e2;
  border-top-width: 2px;
}
.article-list article img {
  display: inline-block;
  height: 120px;
  margin-bottom: 20px;
  width: 120px;
}
.article-list article img.square {
  border-radius: 0;
}
.article-list article h2 {
  margin-bottom: 0;
  margin-top: 0;
}
.article-list article p {
  text-align: justify;
}
.article-list article .meta {
  color: #71857e;
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  margin-bottom: 22px;
}
.article-list article .meta time,
.article-list article .meta span {
  display: block;
}
.article-list article .primary-link {
  display: block;
  margin-bottom: 0;
  margin-top: 16px;
  text-align: left;
}
@media (min-width: 740px) {
  .article-list {
    margin: 32px 0;
  }
  .article-list article {
    padding-bottom: 24px;
    padding-top: 40px;
    text-align: left;
  }
  .article-list article.article-expanded img {
    height: 220px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 220px;
  }
  .article-list article img {
    float: right;
    margin-bottom: 40px;
    margin-left: 40px;
  }
}
@media (min-width: 1020px) {
  .article-list {
    margin: 32px 0;
  }
  .article-list article .meta {
    font-size: 14px;
  }
  .article-list article .primary-link {
    font-size: 16px;
  }
}
@media (min-width: 1260px) {
  .article-list {
    margin: 32px 0;
  }
  .article-list article img {
    margin-bottom: 40px;
    margin-left: 72px;
  }
}
.pagination {
  background-color: #ecf5d9;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 0;
  font-weight: 700;
  margin: 16px 0;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.pagination li {
  display: none;
  font-size: 16px;
  text-align: center;
  padding: 5px;
  min-width: 40px;
  width: 10%;
}
.pagination li a {
  border: 0;
  background: 0;
  color: #7fba00;
  display: inline-block;
  float: none;
  line-height: 1;
  padding: 7px 0;
  width: 30px;
}
.pagination li a:hover {
  color: #4d8504;
  text-decoration: none;
}
.pagination li.current,
.pagination li:first-child,
.pagination li:last-child {
  display: block;
}
.pagination li.current {
  min-width: 50px;
}
.pagination li.current:hover a {
  background: #fff;
}
.pagination li.current a {
  background: #fff;
  border-radius: 50%;
  color: #000;
}
.pagination li.current a:hover {
  color: #000;
}
.pagination li:first-child,
.pagination li:last-child {
  width: 50%;
}
.pagination li:first-child a,
.pagination li:last-child a {
  width: 100%;
}
.pagination li:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 2px solid #fff;
  min-width: 88px;
}
.pagination li:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 2px solid #fff;
  min-width: 58px;
}
@media (min-width: 740px) {
  .pagination {
    display: inline-block;
    margin: 10px 0;
  }
  .pagination li {
    display: inline-block;
    min-width: 0;
    padding: 5px 13px;
    width: auto;
  }
  .pagination li:first-child,
  .pagination li:last-child,
  .pagination li.current {
    display: inline-block;
  }
  .pagination li:first-child,
  .pagination li:last-child {
    min-width: 0;
    padding-left: 24px;
    padding-right: 24px;
    width: auto;
  }
  .pagination li:first-child {
    margin-right: 13px;
  }
  .pagination li:last-child {
    margin-left: 13px;
  }
}
.form {
  border-radius: 5px;
  border: 1px solid #dae6e2;
  padding: 10px;
}
@media (min-width: 740px) {
  .form {
    border-width: 2px;
    padding: 18px;
  }
}
.form section {
  border-bottom: 1px solid #dae6e2;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.form section:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.form section h3 {
  font-size: 18px;
}
@media (min-width: 740px) {
  .form section h3 {
    font-size: 20px;
  }
}
.form section p {
  padding: 9px;
}
.form-title {
  padding-left: 10px;
  padding-right: 10px;
}
.form-group {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 9px;
  position: relative;
  -webkit-transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  -o-transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;
}
.form-horizontal .form-group {
  margin: 10px 0;
}
.form-group .control-label {
  padding: 0;
  text-align: left;
}
@media (min-width: 740px) {
  .form-group .control-label {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 0;
    padding-right: 36px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .form-group .control-label {
    float: left;
    width: 25%;
  }
}
.form-group .control-label span {
  display: block;
}
.form-group .control-label span:not(.no-colon)::after {
  content: ":";
}
.form-group .control-label.checkbox span::after {
  content: "";
}
.form-group.is-required .control-label::after {
  clear: left;
  color: #009fdf;
  content: "required";
  font-style: italic;
  font-family: Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin-top: 0;
}
.form-group.has-success .control-label,
.form-group.has-error .control-label,
.form-group.has-info .control-label,
.form-group.has-focus .control-label,
.form-group.has-warning .control-label {
  color: #000;
}
.form-group.has-error {
  background-color: #fce3de !important;
  border-radius: 4px;
}
.form-group.has-error .radio,
.form-group.has-error .checkbox {
  color: #000;
}
.form-group.has-error .radio input[type="radio"]:checked ~ span:before {
  border-color: #d62906 !important;
  background-color: $red !important;
}
.form-group.has-error .checkbox input[type="checkbox"]:checked ~ span:before {
  border-color: #d62906 !important;
  color: $red !important;
}
.form-group.has-error.is-required .control-label::after {
  color: $red !important;
}
.form-group.has-error .hint i.fa-info {
  background: $red !important;
}
.form-group.has-error .choice-tout .innerspace {
  border-color: #abbcb6 !important;
}
.form-group.has-error .choice-tout [type="radio"]:checked ~ .innerspace,
.form-group.has-error .choice-tout [type="checkbox"]:checked ~ .innerspace {
  border-color: $red !important;
}
.form-group.has-error .choice-tout [type="radio"]:checked ~ .innerspace::before,
.form-group.has-error .choice-tout [type="radio"]:checked ~ .innerspace::after,
.form-group.has-error .choice-tout [type="checkbox"]:checked ~ .innerspace::before,
.form-group.has-error .choice-tout [type="checkbox"]:checked ~ .innerspace::after {
  border-color: $red !important;
  color: $red !important;
}
.form-group.has-error .choice-tout [type="radio"]:checked ~ .innerspace::before,
.form-group.has-error .choice-tout [type="radio"]:checked ~ .innerspace::after {
  background-color: $red !important;
}
.form-group.has-error .form-control:focus,
.form-group.has-error .checkbox input:focus ~ span::before,
.form-group.has-error .radio input:focus ~ span::before,
.form-group.has-error .choice-tout input:focus ~ .innerspace,
.form-group.has-error .choice-tout input:focus ~ .innerspace::before,
.form-group.has-error .choice-tout input:focus ~ .innerspace::after,
.form-group.has-error .file input:focus ~ .file-label .file-button,
.form-group.has-error .search .btn:focus {
  border-color: $red !important;
}

.form-group.has-focus {
  background-color: #d9f1fa;
  border-radius: 4px;
}
.form-group.has-focus .form-control:focus,
.form-group.has-focus .checkbox input:focus ~ span::before,
.form-group.has-focus .radio input:focus ~ span::before,
.form-group.has-focus .choice-tout input:focus ~ .innerspace,
.form-group.has-focus .choice-tout input:focus ~ .innerspace::before,
.form-group.has-focus .choice-tout input:focus ~ .innerspace::after,
.form-group.has-focus .file input:focus ~ .file-label .file-button,
.form-group.has-focus .search .btn:focus {
  border-color: #009fdf;
}
.form-group.has-focus .radio input[type="radio"]:checked ~ span:before {
  border-color: #7fba00;
  background-color: #7fba00;
}
.form-group.has-focus .checkbox input[type="checkbox"]:checked ~ span:before {
  border-color: #7fba00;
  color: #7fba00;
}
.form-group.has-focus .radio input[type="radio"]:focus ~ span:before {
  border-color: #009fdf;
  background-color: #7fba00;
}
.form-group.has-focus .checkbox input[type="checkbox"]:focus ~ span:before {
  border-color: #009fdf;
  color: #7fba00;
}
.form-group.has-focus .search .btn {
  background-color: transparent;
  border-color: transparent;
}
.form-group.has-focus .search .btn:focus {
  outline: 0;
}
.form-group.has-focus.is-required .control-label::after {
  color: #009fdf;
}
.form-group.has-focus .choice-tout .innerspace {
  border-color: #abbcb6;
}
.form-group.has-focus .choice-tout .innerspace::before,
.form-group.has-focus .choice-tout .innerspace::after {
  border-color: #abbcb6;
}
.form-group.has-focus .choice-tout [type="radio"]:checked ~ .innerspace,
.form-group.has-focus .choice-tout [type="checkbox"]:checked ~ .innerspace {
  border-color: #7fba00;
}
.form-group.has-focus .choice-tout [type="radio"]:checked ~ .innerspace::before,
.form-group.has-focus .choice-tout [type="radio"]:checked ~ .innerspace::after,
.form-group.has-focus .choice-tout [type="checkbox"]:checked ~ .innerspace::before,
.form-group.has-focus .choice-tout [type="checkbox"]:checked ~ .innerspace::after {
  border-color: #7fba00;
  color: #7fba00;
}
.form-group.has-focus .choice-tout [type="radio"]:checked ~ .innerspace::before,
.form-group.has-focus .choice-tout [type="radio"]:checked ~ .innerspace::after {
  background-color: #7fba00;
}
.form-group.has-focus .choice-tout [type="radio"]:focus ~ .innerspace,
.form-group.has-focus .choice-tout [type="checkbox"]:focus ~ .innerspace {
  border-color: #009fdf;
}
.form-group.has-focus .choice-tout [type="radio"]:focus ~ .innerspace::before,
.form-group.has-focus .choice-tout [type="radio"]:focus ~ .innerspace::after,
.form-group.has-focus .choice-tout [type="checkbox"]:focus ~ .innerspace::before,
.form-group.has-focus .choice-tout [type="checkbox"]:focus ~ .innerspace::after {
  border-color: #009fdf;
}
.form-group.has-focus .choice-tout [type="checkbox"]:focus:checked ~ .innerspace::before,
.form-group.has-focus .choice-tout [type="checkbox"]:focus:checked ~ .innerspace::after {
  color: #7fba00;
}
.form-group.has-info {
  background-color: #d9f1fa;
  border-color: #009fdf;
  border-radius: 4px;
}
.form-group.has-info .hint i.fa-info {
  background: #009fdf;
}
.form-group.has-info.is-required .control-label::after {
  color: #009fdf;
}
.form-group.has-info .form-control {
  border-color: #009fdf;
}
.form-control {
  border-width: 2px;
  font-size: 16px;
}
@media (min-width: 740px) {
  .form-control {
    font-size: 14px;
    margin: 0;
  }
}
.form-control-short {
  margin-bottom: 10px;
}
@media (min-width: 740px) {
  .form-control-short {
    float: left;
    margin-right: 4.16666%;
    width: 47.91667%;
  }
  .form-control-short:last-child {
    margin-right: 0;
  }
}
.form-control-veryshort {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
@media (min-width: 740px) {
  .form-control-veryshort {
    float: left;
    width: 16.66667%;
  }
}
@media (min-width: 740px) {
  .form-control-veryshort {
    float: left;
  }
  .form-control-veryshort:last-child {
    margin-right: 0;
  }
}
@media (min-width: 400px) {
  .form-control-inline,
  .form-search .form-control + .btn {
    float: left;
    margin-right: 4.16666%;
    width: 47.91667%;
  }
  .form-control-inline:last-child,
  .form-search .form-control + .btn:last-child {
    margin-right: 0;
  }
}
@media (min-width: 740px) {
  .fieldset {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding: 0;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .fieldset {
    float: left;
    width: 75%;
  }
}
.fieldset-full {
  width: 100%;
}
.hint i.fa-info {
  background-color: #009fdf;
  border-radius: 50%;
  color: #fff;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
}
@media (min-width: 740px) {
  .hint i.fa-info {
    left: 142px;
    top: 10px;
    right: auto;
  }
}
@media (min-width: 1020px) {
  .hint i.fa-info {
    left: 122px;
  }
}
@media (min-width: 1260px) {
  .hint i.fa-info {
    left: 162px;
  }
}
.hint i.fa-info:hover {
  cursor: pointer;
}
.hint i.fa-info::before {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hint .hint-content {
  color: #000;
  display: none;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0;
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding: 0;
  will-change: opacity;
  -webkit-transition: opacity 250ms ease-in-out;
  -o-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
@media (min-width: 740px) {
  .hint .hint-content {
    float: left;
    width: 75%;
  }
}
@media (min-width: 740px) {
  .hint .hint-content {
    margin-left: 25%;
  }
}
.hint .hint-content p {
  font-style: italic;
  margin-bottom: 10px;
  padding: 0;
}
.hint .hint-content img {
  max-width: 100%;
}
.form-group.has-info .hint-content {
  display: block;
}
.form-group.has-info .hint-content.show {
  opacity: 1;
}
.help-block {
  line-height: 1.8em;
  margin-bottom: 0;
  padding-bottom: 0;
  font-style: italic;
}
.help-block else {
  font-size: 11px;
}
.form-group.has-error .help-block {
  color: $red;
}
.form-group.has-focus .help-block {
  color: #000;
}
textarea {
  max-height: 20em;
  max-width: 100%;
}
textarea[maxlength] + span,
input[maxlength] + span {
  line-height: 2;
}
.has-error textarea[maxlength] + span,
textarea[maxlength] + span.has-error,
.has-focus textarea[maxlength] + span.has-error,
.has-error input[maxlength] + span,
input[maxlength] + span.has-error,
.has-focus input[maxlength] + span.has-error {
  color: $validation-red;
}
.has-focus textarea[maxlength] + span,
.has-focus input[maxlength] + span {
  color: #057dbc;
}
.checkbox,
.radio {
  display: block;
  padding: 0;
}
.checkbox + .checkbox,
.checkbox + .radio,
.radio + .checkbox,
.radio + .radio {
  margin-top: 10px;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"],
.radio input[type="checkbox"],
.radio input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.checkbox label,
.radio label {
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.checkbox label:before,
.radio label:before {
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
}
.checkbox span,
.checkbox span:before,
.radio span,
.radio span:before {
  vertical-align: middle;
}
.checkbox span,
.radio span {
  display: inline-block;
  padding-left: 0;
}
.checkbox span:before,
.radio span:before {
  content: "";
  display: inline-block;
  height: 22px;
  margin-right: 10px;
  width: 22px;
}
.checkbox input[type="checkbox"]:checked ~ span:before,
.checkbox input[type="radio"]:checked ~ span:before,
.radio input[type="checkbox"]:checked ~ span:before,
.radio input[type="radio"]:checked ~ span:before {
  border-color: #7fba00;
}
.form-horizontal .checkbox,
.form-horizontal .radio {
  padding: 0;
}
.checkbox span:before {
  border: 2px solid #abbcb6;
  border-radius: 4px;
  line-height: 18px;
  text-align: center;
}
.checkbox input[type="checkbox"]:checked ~ span:before {
  color: #7fba00;
  content: "\f00c";
  font-family: "FontAwesome";
}
.radio span:before {
  background-color: transparent;
  background-clip: content-box;
  border: 2px solid #abbcb6;
  border-radius: 50%;
  padding: 3px;
}
.radio input[type="radio"]:checked ~ span:before {
  background-color: #7fba00;
}
.file {
  display: block;
  margin-bottom: 14px;
  line-height: 1;
  width: 100%;
}
@media (min-width: 740px) {
  .file {
    display: table;
  }
}
.file + .file {
  margin-top: 14px;
}
.file [type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.file .file-label {
  display: block;
}
@media (min-width: 740px) {
  .file .file-label {
    float: left;
  }
}
.file .file-label:hover {
  cursor: pointer;
}
.file .file-button {
  border: 2px solid rgba(0, 0, 0, 0.11765);
  border-radius: 5px;
  display: block;
  float: left;
  font-weight: normal;
  margin-right: 8px;
  padding: 6px 12px;
  white-space: nowrap;
}
@media (min-width: 740px) {
  .file .file-button {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}
.file .file-button:hover {
  background: rgba(0, 0, 0, 0.07);
}
.file .file-name {
  display: block;
  min-height: 30px;
  margin-left: 108px;
  padding-top: 8px;
  word-wrap: break-word;
}
@media (min-width: 740px) {
  .file .file-name {
    display: inline-block;
    max-width: 167px;
    margin-left: 0;
    min-height: 0;
    padding-top: 0;
    vertical-align: middle;
  }
}
.file .file-size {
  display: block;
  margin-top: 8px;
  margin-left: 108px;
  text-align: left;
}
@media (min-width: 740px) {
  .file .file-size {
    line-height: 30px;
    margin-top: 0;
    margin-left: 0;
    text-align: right;
    vertical-align: middle;
  }
}
.file-size {
  color: #009fdf;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.file-summary {
  margin-top: 22px;
  text-align: right;
}
.file-summary a {
  color: #7fba00;
}
.file-summary > span {
  display: block;
}
.file-summary > span:first-child {
  color: #009fdf;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.form-group.has-error .file-size {
  color: $red;
}
.form-group.has-error .file-summary > span:first-child {
  color: #000;
}
.form-group.has-error .file-summary a {
  color: #000;
  text-decoration: underline;
}
.choice-touts {
  padding: 0;
}
.choice-tout {
  display: block;
  margin-bottom: 10px;
}
.choice-tout label {
  cursor: pointer;
  display: block;
  margin: 0;
}
.choice-tout .innerspace {
  border: 2px solid #dae6e2;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px;
}
.choice-tout .innerspace::before,
.choice-tout .innerspace::after {
  border: 2px solid #abbcb6;
  color: transparent;
  font-family: "FontAwesome";
  float: left;
  height: 22px;
  line-height: 18px;
  margin-right: 8px;
  text-align: center;
  width: 22px;
}
.choice-tout .innerspace img {
  float: left;
  margin-right: 10px;
  height: 60px;
}
.choice-tout .innerspace h4 {
  font-size: 14px;
  margin: 2px 0 0;
}
.choice-tout .innerspace p {
  margin: 0;
  padding: 0;
}
.choice-tout [type="radio"],
.choice-tout [type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.choice-tout [type="radio"]:checked ~ .innerspace,
.choice-tout [type="checkbox"]:checked ~ .innerspace {
  border-color: #7fba00;
}
.choice-tout [type="radio"]:checked ~ .innerspace::before,
.choice-tout [type="radio"]:checked ~ .innerspace::after,
.choice-tout [type="checkbox"]:checked ~ .innerspace::before,
.choice-tout [type="checkbox"]:checked ~ .innerspace::after {
  border-color: #7fba00;
  color: #7fba00;
}
.choice-tout [type="radio"] ~ .innerspace::before,
.choice-tout [type="radio"] ~ .innerspace::after {
  background-clip: content-box;
  border-radius: 50%;
  padding: 3px;
}
.choice-tout [type="radio"] ~ .innerspace::before {
  content: "";
}
.choice-tout [type="radio"]:checked ~ .innerspace::before,
.choice-tout [type="radio"]:checked ~ .innerspace::after {
  background-color: #7fba00;
}
.choice-tout [type="checkbox"] ~ .innerspace::before,
.choice-tout [type="checkbox"] ~ .innerspace::after {
  border-radius: 4px;
}
.choice-tout [type="checkbox"] ~ .innerspace::before {
  content: "\f00c";
}
@media (min-width: 740px) {
  .choice-touts {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .choice-touts .choice-tout {
    cursor: pointer;
    display: inline-block;
    width: 156px;
  }
  .choice-touts .choice-tout + .choice-tout {
    margin-left: 6px;
  }
  .choice-touts .choice-tout:nth-child(3n + 4) {
    margin-left: 0;
  }
  .choice-touts .choice-tout label {
    height: 100%;
  }
  .choice-touts .innerspace {
    height: 100%;
    margin-left: 0;
    padding: 10px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .choice-touts .innerspace::before,
  .choice-touts .innerspace::after {
    display: inline-block;
    margin-top: 10px;
    position: static;
  }
  .choice-touts .innerspace::after {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-right: 0;
  }
  .choice-touts .innerspace img {
    float: none;
    margin-right: 0;
    margin-bottom: 16px;
  }
  .choice-touts .innerspace > div {
    width: 100%;
  }
  .choice-touts .innerspace h4 {
    font-size: 16px;
    margin: 0;
    padding-bottom: 8px;
  }
  .choice-touts .innerspace p {
    font-size: 15px;
  }
  .choice-touts [type="radio"] ~ .innerspace::before {
    content: none;
  }
  .choice-touts [type="radio"] ~ .innerspace::after {
    content: "";
  }
  .choice-touts [type="checkbox"] ~ .innerspace::before {
    content: none;
  }
  .choice-touts [type="checkbox"] ~ .innerspace::after {
    content: "";
  }
}
@media (min-width: 1020px) {
  .choice-touts + .choice-tout {
    width: 136px;
  }
}
@media (min-width: 1260px) {
  .choice-touts + .choice-tout {
    width: 176px;
  }
}
.form-search .form-control {
  float: left;
  margin-right: 4.16666%;
  width: 47.91667%;
  vertical-align: middle;
}
.form-search .form-control + .btn {
  margin: 0;
  padding: 4px;
  text-align: left;
}
@media (min-width: 400px) {
  .form-search .form-control + .btn {
    text-align: center;
  }
}
.form-search .form-autocomplete {
  clear: both;
  margin: 20px 0;
}
.form-search .form-autocomplete li {
  display: block;
  margin: 10px 0;
}
.form-search .form-autocomplete a {
  color: #7fba00;
  line-height: 1;
}
.progress-tracker {
  text-align: center;
  margin-bottom: 30px;
  margin-left: 0;
  min-width: 212px;
  position: relative;
  text-indent: 0;
  white-space: nowrap;
}
.progress-tracker::after {
  background: #dae6e2;
  display: block;
  height: 10px;
  position: absolute;
  right: 5%;
  top: 20px;
  width: 90%;
  z-index: 0;
}
@media (min-width: 740px) {
  .progress-tracker::after {
    top: 25px;
  }
}
.progress-tracker .step::before,
.progress-tracker .ellipsis::before,
.progress-tracker .current::before,
.progress-tracker .complete::before {
  content: " ";
  display: block;
  height: 10px;
  position: absolute;
  right: 8px;
  top: 20px;
  width: 100%;
  z-index: 0;
}
@media (min-width: 740px) {
  .progress-tracker .step::before,
  .progress-tracker .ellipsis::before,
  .progress-tracker .current::before,
  .progress-tracker .complete::before {
    top: 25px;
  }
}
.progress-tracker .step a,
.progress-tracker .step a:hover,
.progress-tracker .step a:focus,
.progress-tracker .ellipsis a,
.progress-tracker .ellipsis a:hover,
.progress-tracker .ellipsis a:focus,
.progress-tracker .current a,
.progress-tracker .current a:hover,
.progress-tracker .current a:focus,
.progress-tracker .complete a,
.progress-tracker .complete a:hover,
.progress-tracker .complete a:focus {
  color: inherit;
}
.progress-tracker .step .step-content::before,
.progress-tracker .ellipsis .ellipsis-content span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.progress-tracker .current::before,
.progress-tracker .complete::before {
  width: 100%;
  z-index: 1;
}
.progress-tracker .current:first-child::before,
.progress-tracker .complete:first-child::before {
  content: none;
}
.progress-tracker[data-step-total] .step,
.progress-tracker[data-step-total] .ellipsis {
  display: inline-block;
  height: 50px;
  margin: 0 -2px;
  position: relative;
  vertical-align: middle;
  width: 25%;
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total] .step,
  .progress-tracker[data-step-total] .ellipsis {
    height: 60px;
  }
}
.progress-tracker[data-step-total] .step::before {
  background-color: #dae6e2;
}
.progress-tracker[data-step-total] .step:first-child {
  width: 50px;
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total] .step:first-child {
    width: 60px;
  }
}
.progress-tracker[data-step-total] .step.first {
  width: 50px;
}
.progress-tracker[data-step-total] .step.first::before {
  content: none;
}
.progress-tracker[data-step-total] .step .step-content::before {
  content: "Step: ";
  display: inline;
}
.progress-tracker[data-step-total] .step.current .step-content::before {
  content: "Current step: ";
  display: inline;
}
.progress-tracker[data-step-total] .step-content {
  background: #fff;
  border: 8px solid #dae6e2;
  border-radius: 50%;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 18px;
  font-weight: bold;
  float: right;
  height: 50px;
  line-height: 38px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 50px;
  z-index: 2;
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total] .step-content {
    border-width: 10px;
    height: 60px;
    font-size: 22px;
    line-height: 42px;
    width: 60px;
  }
}
.progress-tracker[data-step-total] .ellipsis::before,
.progress-tracker[data-step-total] .ellipsis + .step::before {
  background: 0;
}
.progress-tracker[data-step-total] .ellipsis-content,
.progress-tracker[data-step-total] .ellipsis-content::before,
.progress-tracker[data-step-total] .ellipsis-content::after {
  background-color: #dae6e2;
  border-radius: 50%;
  content: "";
  height: 10px;
  position: absolute;
  width: 10px;
}
.progress-tracker[data-step-total] .ellipsis-content {
  right: 20px;
  top: 20px;
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total] .ellipsis-content {
    right: 25px;
    top: 25px;
  }
}
.progress-tracker[data-step-total] .ellipsis-content::before {
  left: -20px;
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total] .ellipsis-content::before {
    left: -25px;
  }
}
.progress-tracker[data-step-total] .ellipsis-content::after {
  right: -20px;
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total] .ellipsis-content::after {
    right: -25px;
  }
}
.progress-tracker[data-step-total="2"] .step,
.progress-tracker[data-step-total="2"] .ellipsis {
  width: calc(100% - 60px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="2"] .step,
  .progress-tracker[data-step-total="2"] .ellipsis {
    width: calc(33.33333% - 20px);
  }
}
.progress-tracker[data-step-total="3"] .step,
.progress-tracker[data-step-total="3"] .ellipsis {
  width: calc(50% - 30px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="3"] .step,
  .progress-tracker[data-step-total="3"] .ellipsis {
    width: calc(33.33333% - 20px);
  }
}
.progress-tracker[data-step-total="4"] .step,
.progress-tracker[data-step-total="4"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="4"] .step,
  .progress-tracker[data-step-total="4"] .ellipsis {
    width: calc(33.33333% - 20px);
  }
}
.progress-tracker[data-step-total="5"] .step,
.progress-tracker[data-step-total="5"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="5"] .step,
  .progress-tracker[data-step-total="5"] .ellipsis {
    width: calc(25% - 15px);
  }
}
.progress-tracker[data-step-total="6"] .step,
.progress-tracker[data-step-total="6"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="6"] .step,
  .progress-tracker[data-step-total="6"] .ellipsis {
    width: calc(20% - 12px);
  }
}
.progress-tracker[data-step-total="7"] .step,
.progress-tracker[data-step-total="7"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="7"] .step,
  .progress-tracker[data-step-total="7"] .ellipsis {
    width: calc(16.66667% - 10px);
  }
}
.progress-tracker[data-step-total="8"] .step,
.progress-tracker[data-step-total="8"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="8"] .step,
  .progress-tracker[data-step-total="8"] .ellipsis {
    width: calc(16.66667% - 10px);
  }
}
.progress-tracker[data-step-total="9"] .step,
.progress-tracker[data-step-total="9"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="9"] .step,
  .progress-tracker[data-step-total="9"] .ellipsis {
    width: calc(16.66667% - 10px);
  }
}
.progress-tracker[data-step-total="10"] .step,
.progress-tracker[data-step-total="10"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="10"] .step,
  .progress-tracker[data-step-total="10"] .ellipsis {
    width: calc(16.66667% - 10px);
  }
}
.progress-tracker[data-step-total="11"] .step,
.progress-tracker[data-step-total="11"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="11"] .step,
  .progress-tracker[data-step-total="11"] .ellipsis {
    width: calc(16.66667% - 10px);
  }
}
.progress-tracker[data-step-total="12"] .step,
.progress-tracker[data-step-total="12"] .ellipsis {
  width: calc(33.33333% - 20px);
}
@media (min-width: 740px) {
  .progress-tracker[data-step-total="12"] .step,
  .progress-tracker[data-step-total="12"] .ellipsis {
    width: calc(16.66667% - 10px);
  }
}
.progress-tracker.numbered .step.complete .step-content {
  border-color: #009fdf;
  color: #009fdf;
}
.progress-tracker.numbered .step.complete::before {
  background-color: #009fdf;
}
.progress-tracker.numbered .step.current .step-content {
  border-color: #009fdf;
  color: #009fdf;
}
.progress-tracker.numbered .step.current::before {
  background-color: #009fdf;
}
.progress-tracker.numbered .step .step-content span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
@media (min-width: 740px) {
  .progress-tracker.network-status {
    height: 100px;
  }
}
.progress-tracker.network-status .step .step-content span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 60px;
  left: 50%;
  visibility: hidden;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 740px) {
  .progress-tracker.network-status .step .step-content span {
    visibility: visible;
  }
}
@media (min-width: 1020px) {
  .progress-tracker.network-status .step .step-content span {
    width: 140px;
  }
}
.progress-tracker.network-status .step:last-child span {
  direction: rtl;
  right: -8px;
  left: auto;
  text-align: right;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.progress-tracker.network-status .step:first-child span {
  right: auto;
  left: -8px;
  text-align: left;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.progress-tracker.network-status .step.complete .step-content,
.progress-tracker.network-status .step.current .step-content {
  border-color: #7fba00;
  color: #7fba00;
}
.progress-tracker.network-status .step.complete .step-content span,
.progress-tracker.network-status .step.current .step-content span {
  color: #000;
}
.progress-tracker.network-status .step.complete::before,
.progress-tracker.network-status .step.current::before {
  background-color: #7fba00;
}
.progress-tracker.network-status .step.complete .fa,
.progress-tracker.network-status .step.current .fa {
  background-color: #7fba00;
  color: #fff;
}
.progress-tracker.network-status .fa {
  background-color: transparent;
  border-radius: 50%;
  display: block;
  left: 50%;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 30px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.progress-tracker.network-status .fa-search {
  font-size: 18px;
}
.progress-tracker.network-status .fa-thumbs-up {
  font-size: 16px;
}
.progress-tracker.network-status .fa-thumbs-up::before {
  position: relative;
  top: -1px;
}
.carousel {
  border-bottom: 1px solid #dae6e2;
  border-top: 1px solid #dae6e2;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
}
@media (min-width: 740px) {
  .carousel {
    background-color: #009fdf;
  }
}
.carousel .tout {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  padding-bottom: 45px;
  width: 100%;
}
@media (max-width: 739px) {
  .carousel .tout {
    height: 285px;
  }
}
@media (min-width: 740px) {
  .carousel .tout {
    height: 257px;
  }
}
@media (min-width: 740px) {
  .carousel .tout .tout-inner {
    height: 100%;
  }
}
@media (min-width: 740px) {
  .carousel .tout .tout-inner .tout-image {
    height: 100%;
    margin-top: 17px;
    margin-bottom: inherit;
  }
}
.carousel .tout.photo {
  padding-bottom: 0;
}
.carousel .tout.photo .tout-image {
  margin-bottom: 0;
}
@media (max-width: 739px) {
  .carousel .tout.photo .tout-image {
    bottom: 0;
    position: absolute;
    height: 240px;
    visibility: hidden;
  }
}
@media (min-width: 740px) {
  .carousel .tout.photo .tout-image {
    height: 257px;
    margin-top: 0;
  }
  .carousel .tout .tout-title {
    margin-bottom: 5px;
    font-size: 28px;
  }
  .carousel .tout p {
    margin-bottom: 0;
  }
}
@media (max-width: 739px) {
  .carousel .tout.photo .tout-content {
    margin-bottom: 240px;
  }
  .tout p {
    clear: none;
  }
}
.carousel .flickity-viewport {
  -webkit-transition: height 150ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: height 150ms cubic-bezier(0, 0, 0.2, 1);
  transition: height 150ms cubic-bezier(0, 0, 0.2, 1);
}
.carousel .flickity-page-dots {
  bottom: 10px;
  font-size: 0;
  left: 0;
  line-height: 34px;
  margin: 0 auto;
  right: 0;
  text-align: center;
  text-indent: 0;
  width: auto;
}
@media (max-width: 739px) {
  .carousel .flickity-page-dots {
    bottom: 20px;
    font-size: 0;
    left: 45px;
    line-height: 34px;
    margin: 0 auto;
    right: 0;
    text-align: left;
    text-indent: 0;
    width: auto;
  }
}
.carousel .flickity-page-dots .dot {
  background: #dae6e2;
  border: 0;
  height: 14px;
  margin: 0 10px;
  opacity: 1;
  width: 14px;
  vertical-align: bottom;
  -webkit-transition: background 500ms ease-in-out;
  -o-transition: background 500ms ease-in-out;
  transition: background 500ms ease-in-out;
}
.carousel .carousel-play-pause {
  background-color: #fff;
  border: 2px solid #7fba00;
  border-radius: 50%;
  bottom: 10px;
  color: #7fba00;
  display: inline-block;
  height: 34px;
  line-height: 30px;
  position: absolute;
  left: 10px;
  text-align: center;
  width: 34px;
  z-index: 1;
  -webkit-transition: border-color 500ms ease-in-out, color 500ms ease-in-out, background-color 500ms ease-in-out;
  -o-transition: border-color 500ms ease-in-out, color 500ms ease-in-out, background-color 500ms ease-in-out;
  transition: border-color 500ms ease-in-out, color 500ms ease-in-out, background-color 500ms ease-in-out;
}
.carousel .carousel-play-pause .fa-play {
  margin-left: 3px;
}
.carousel[data-theme="white"] .flickity-page-dots .dot {
  background: #dae6e2;
}
.carousel[data-theme="white"] .flickity-page-dots .dot.is-selected {
  background: #009fdf;
}
.carousel[data-theme="blue"] .flickity-page-dots .dot {
  background: #fff;
  opacity: 0.3;
}
.carousel[data-theme="blue"] .flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.carousel[data-theme="blue"] .carousel-play-pause {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
@media (min-width: 740px) {
  .carousel {
    border-width: 2px 0;
    margin-left: auto;
    margin-right: auto;
    width: 700px;
  }
  .carousel .flickity-page-dots {
    left: 64px;
    text-align: left;
    bottom: 18px;
  }
  .carousel .flickity-page-dots .dot {
    height: 16px;
    width: 16px;
  }
}
@media (min-width: 1020px) {
  .carousel {
    width: 940px;
  }
  .carousel .flickity-page-dots {
    left: 64px;
    text-align: left;
    bottom: 10px;
  }
  .carousel .flickity-page-dots .dot {
    vertical-align: middle;
  }
  .carousel .carousel-play-pause {
    left: 10px;
    right: auto;
  }
}
@media (min-width: 1260px) {
  .carousel {
    width: 1180px;
  }
  .carousel .flickity-page-dots {
    left: 64px;
    bottom: 10px;
  }
  .carousel .flickity-page-dots .dot {
    vertical-align: middle;
  }
  .carousel .carousel-play-pause {
    left: 10px;
    right: auto;
  }
}
.page-menu {
  border: 2px solid #dae6e2;
  border-radius: 5px;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  -webkit-transition: border-color 250ms ease-in-out;
  -o-transition: border-color 250ms ease-in-out;
  transition: border-color 250ms ease-in-out;
}
.page-menu li,
.page-menu .parent {
  font-size: 14px;
  line-height: 1;
  margin: 0;
  padding-left: 14px;
}
.page-menu li::before,
.page-menu .parent::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7fba00;
  content: "";
  float: left;
  font-size: 15px;
  line-height: 32px;
  margin-right: 10px;
}
.page-menu i {
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 14px;
  float: right;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
}
.page-menu i::before {
  border-radius: 50%;
  background-color: #7fba00;
  display: block;
  height: 20px;
  width: 20px;
}
.page-menu a {
  color: #71857e;
  display: block;
  padding: 10px 0 8px;
}
.page-menu a:hover {
  color: #000;
}
.page-menu .current > a {
  color: #000;
}
.page-menu .current::before {
  color: #009fdf;
}
.page-menu .parent {
  margin-bottom: 0;
}
.page-menu .parent::before {
  content: "";
}
.page-menu .parent ~ li {
  display: none;
}
.page-menu .parent button {
  background: 0;
  border: 0;
  color: #7fba00;
  cursor: pointer;
  float: right;
  font-size: 19.85px;
  line-height: 19px;
  padding: 8px 7px 7px;
  position: relative;
  text-align: center;
  z-index: 1;
}
.page-menu .parent button::before {
  display: block;
  height: 17px;
  width: 17px;
}
.page-menu .primary {
  height: 0;
  margin: 0;
  overflow: hidden;
  list-style: none;
}
.page-menu .primary > li > a {
  padding-right: 30px;
}
.page-menu .primary > li:last-child {
  padding-bottom: 16px;
}
.page-menu .secondary {
  display: none;
}
.page-menu .secondary,
.page-menu .tertiary,
.page-menu .fourth {
  list-style: none;
}
.page-menu .current > .secondary,
.page-menu .can-collapse > .secondary {
  display: block;
}
.page-menu.open {
  border-color: #7fba00;
}
.page-menu.open .primary {
  height: auto;
}
@media (min-width: 1020px) {
  .page-menu {
    border: 0;
    border-color: transparent;
    border-radius: 0;
    margin-bottom: 40px;
  }
  .page-menu li {
    font-size: 14px;
  }
  .page-menu li i {
    display: block;
  }
  .page-menu li.current > a {
    color: #000;
  }
  .page-menu li.current::before {
    color: #009fdf;
  }
  .page-menu li.can-collapse .secondary {
    height: auto;
  }
  .page-menu li.can-expand .secondary {
    height: 0;
    overflow: hidden;
  }
  .page-menu .parent,
  .page-menu .primary li {
    padding-left: 0;
  }
  .page-menu .parent,
  .page-menu .primary > li {
    border-bottom: 1px solid #7fba00;
    font-size: 16px;
  }
  .page-menu .parent::before,
  .page-menu .primary > li::before {
    line-height: 40px;
  }
  .page-menu .parent > a,
  .page-menu .primary > li > a {
    padding-bottom: 12px;
    padding-left: 0;
    padding-top: 13px;
  }
  .page-menu .primary {
    height: auto;
    overflow: visible;
  }
  .page-menu .primary > li:last-child {
    padding-bottom: 0;
  }
  .page-menu .secondary {
    display: block;
  }
  .page-menu .secondary > li:last-child {
    padding-bottom: 8px;
  }
  .page-menu .parent::before {
    content: "";
  }
  .page-menu .parent button {
    display: none;
  }
}
.ratings h3 {
  margin: 18px 0 4px;
}
.ratings .btn {
  display: block;
  margin: 20px auto 18px;
}
.rating .rating-label,
.rating .rating-stars {
  display: block;
}
.rating .rating-label {
  border-bottom: 1px solid #bad80a;
  color: #009fdf;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 18px;
  line-height: 1;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
.rating .rating-stars {
  text-align: left;
}
.rating .rating-stars ul {
  display: inline-block;
  margin: 0 !important;
  text-indent: 0 !important;
}
.rating .rating-stars ul:hover .star .fa::before {
  color: #4cc2f1;
}
.rating .rating-stars ul:hover .star:hover ~ .star .fa::before {
}
.rating .rating-stars ul:hover .star:hover ~ .star.on .fa::before {
  color: #009fdf;
}
.rating .star {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  margin: 0 -4px;
  padding: 0 3px;
}
.rating .star span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.rating .star .fa::before {
  content: "";
}
.rating .star.on .fa::before {
  color: #009fdf;
}
.rating .star::before {
  display: none;
}
.rating .star-button {
  background: 0;
  border: 0;
  margin: 0;
  padding: 0;
}
.rating input[type="range"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.rating-inline {
  border-bottom: 1px solid #bad80a;
  box-sizing: content-box;
  display: table;
  margin: 0 -10px;
  padding: 12px 10px 9px;
  width: 100%;
}
.rating-inline .rating-label,
.rating-inline .rating-stars {
  display: table-cell;
  width: 55%;
  vertical-align: middle;
}
.rating-inline .rating-label {
  border-bottom: 0;
  color: #000;
  font-family: Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: normal;
  padding-bottom: 0;
}
.rating-inline .rating-stars {
  text-align: right;
}
@media (min-width: 740px) {
  .ratings h3 {
    margin-top: 10px;
  }
  .ratings .btn {
    margin-bottom: 16px;
  }
  .rating {
    margin: 20px 0;
  }
  .rating-inline {
    margin: 0 -18px;
    padding: 12px 18px 9px;
  }
}
@media (min-width: 1020px) {
  .ratings .btn {
    margin-bottom: 4px;
    margin-top: 24px;
  }
}
.cookiepolicy {
  display: none;
  background: #71857e;
  overflow: hidden;
}
.cookiepolicy-inner {
  margin: 10px 0 15px;
}
.cookiepolicy p {
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  text-align: justify;
}
.cookiepolicy p a {
  text-decoration: underline;
}
.cookiepolicy .btn {
  border-radius: 30px;
  font-size: 16px;
  line-height: 1;
  margin: 15px 14px 5px;
  padding: 12px 18px;
}
@media (min-width: 740px) {
  .cookiepolicy-inner {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .cookiepolicy-inner:before,
  .cookiepolicy-inner:after {
    content: " ";
    display: table;
  }
  .cookiepolicy-inner:after {
    clear: both;
  }
  .cookiepolicy-content {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .cookiepolicy-content {
    float: left;
    width: 75%;
  }
}
@media (min-width: 740px) {
  .cookiepolicy .btn {
    font-size: 12px;
    margin: 15px 0 0;
    padding: 12px 17px;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .cookiepolicy .btn {
    float: left;
    width: 16.66667%;
  }
}
@media (min-width: 740px) and (min-width: 740px) {
  .cookiepolicy .btn {
    left: 8.33333%;
  }
}
.droppop .droppop-button {
  margin: 0;
  position: relative;
}
.droppop .droppop-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.15);
  display: none;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  z-index: 4;
}
.droppop .droppop-content h1:first-child,
.droppop .droppop-content h2:first-child,
.droppop .droppop-content h3:first-child,
.droppop .droppop-content h4:first-child,
.droppop .droppop-content ul:first-child,
.droppop .droppop-content ol:first-child,
.droppop .droppop-content p:first-child,
.droppop .droppop-content div:first-child {
  margin-top: 0;
}
.droppop.droppop-open .droppop-button {
  z-index: 5;
}
.share {
  float: right;
  margin: 2px 0;
  position: relative;
}
.share.droppop-open .share-button {
  background-color: #fff;
  border-color: #71857e;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
  color: #71857e;
}
.share .share-button {
  background-color: #abbcb6;
  border: 1px solid #abbcb6;
  border-bottom: 0;
  color: #fff;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  padding: 6px 13px 5px;
  -webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.share .share-content {
  border: 1px solid #71857e;
  border-radius: 5px 0 5px 5px;
  margin-top: -1px;
  padding: 10px;
  right: 0;
  white-space: nowrap;
}
.share .social-button {
  background: 0;
  border: 0;
  border: 1px solid #71857e;
  border-radius: 50%;
  cursor: pointer;
  color: #71857e;
  display: inline-block;
  font-size: 20px;
  height: 40px;
  margin-left: 6px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 40px;
  -webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.share .social-button:first-child {
  margin: 0;
}
.share .social-button:hover {
  background-color: #71857e;
  color: #fff;
}
.share .social-button .fa {
  vertical-align: middle;
}
@media (max-width: 739px) {
  .share .share-content {
    padding: 6px;
  }
  .share .social-button {
    margin-left: 2px;
  }
}
@media (min-width: 740px) {
  .share .share-button {
    font-size: 18px;
  }
}
.downloads {
  font-size: 16px;
  list-style: none;
  margin-left: 0;
}
.downloads li {
  margin: 16px 0 20px;
}
.downloads li:last-child {
  margin-bottom: 0;
}
a.download {
  color: #7fba00;
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  min-height: 1.6em;
  padding-top: 0.2em;
  padding-left: 2.22em;
  position: relative;
}
a.download:hover {
  color: #4d8504;
  text-decoration: none;
}
a.download::before {
  display: block;
  font-size: 1.6em;
  left: 0;
  position: absolute;
  top: 0;
  vertical-align: middle;
}
@media (min-width: 740px) {
  .downloads {
    font-size: 18px;
  }
  .downloads li:first-child {
    margin-top: 12px;
  }
}
@media (min-width: 1020px) {
  .downloads li:first-child {
    margin-top: 24px;
  }
}
.table a.download {
  margin-top: -0.2em;
}
.download::before {
  display: inline-block;
  font: normal normal normal 14px / 1 ThamesWater;
  font-size: inherit;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
}
.download[href$=".pdf"]::before,
.download[href$=".jpg"]::before,
.download[href$=".jpeg"]::before,
.download[href$=".doc"]::before,
.download[href$=".docx"]::before,
.download[href$=".dot"]::before,
.download[href$=".dotx"]::before,
.download[href$=".gif"]::before,
.download[href$=".ppt"]::before,
.download[href$=".pptx"]::before,
.download[href$=".pot"]::before,
.download[href$=".potx"]::before,
.download[href$=".ppsx"]::before,
.download[href$=".ppstm"]::before,
.download[href$=".eps"]::before,
.download[href$=".xls"]::before,
.download[href$=".xltx"]::before,
.download[href$=".xlt"]::before,
.download[href$=".xml"]::before,
.download[href$=".xlsb"]::before,
.download[href$=".xlsm"]::before,
.download[href$=".xltm"]::before,
.download[href$=".xlam"]::before,
.download[href$=".xla"]::before {
  margin-right: 4px;
  vertical-align: middle;
}
.download[href$=".pdf"]::before {
  content: "";
}
.download[href$=".jpg"]::before,
.download[href$=".jpeg"]::before {
  content: "";
}
.download[href$=".doc"]::before,
.download[href$=".docx"]::before,
.download[href$=".dot"]::before,
.download[href$=".dotx"]::before {
  content: "";
}
.download[href$=".gif"]::before {
  content: "";
}
.download[href$=".ppt"]::before,
.download[href$=".pptx"]::before,
.download[href$=".pot"]::before,
.download[href$=".potx"]::before,
.download[href$=".ppsx"]::before,
.download[href$=".ppstm"]::before {
  content: "";
}
.download[href$=".eps"]::before {
  content: "";
}
.download[href$=".xls"]::before,
.download[href$=".xltx"]::before,
.download[href$=".xlt"]::before,
.download[href$=".xml"]::before,
.download[href$=".xlsb"]::before,
.download[href$=".xlsm"]::before,
.download[href$=".xltm"]::before,
.download[href$=".xlam"]::before,
.download[href$=".xla"]::before {
  content: "";
}
.table-wrapper {
  border-radius: 5px;
  border: 2px solid #009fdf;
  margin: 20px 0;
  overflow: auto;
  overflow-y: hidden;
  position: relative;
}
.table-wrapper .pinned {
  background: #fff;
  position: absolute;
  left: 0;
  overflow: auto;
  overflow-y: hidden;
  z-index: 1;
}
.table-wrapper .scrollable {
  border-left: 2px solid #009fdf;
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 2;
}
.table-wrapper .scrollable thead > tr > th,
.table-wrapper .scrollable thead > tr > td {
  background: #4cc2f1;
}
.table-wrapper .scrollable thead > tr > th:first-child,
.table-wrapper .scrollable thead > tr > td:first-child,
.table-wrapper .scrollable tbody > tr > th:first-child,
.table-wrapper .scrollable tbody > tr > td:first-child {
  display: none;
}
.table {
  margin-bottom: 0;
}
.table th[data-sort],
.table td[data-sort] {
  cursor: pointer;
}
.table th[data-sort]::after,
.table td[data-sort]::after {
  content: "";
  display: inline-block;
  font-family: FontAwesome;
  margin-left: 6px;
  vertical-align: middle;
}
.table th[data-sort].sorting-asc::after,
.table th[data-sort].sorting-desc::after,
.table td[data-sort].sorting-asc::after,
.table td[data-sort].sorting-desc::after {
  line-height: 0.5;
}
.table th[data-sort].sorting-asc::after,
.table td[data-sort].sorting-asc::after {
  content: "";
  padding-top: 6px;
}
.table th[data-sort].sorting-desc::after,
.table td[data-sort].sorting-desc::after {
  content: "";
  padding-bottom: 6px;
}
.table a {
  color: #7fba00;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
}
.table p {
  margin-bottom: 0;
  text-align: left;
}
.table p:first-child {
  margin-top: 0;
}
.table thead > tr > th,
.table thead > tr > td,
.table tbody > tr > th,
.table tbody > tr > td {
  border: 0;
}
.table thead > tr > th,
.table thead > tr > td {
  background: #009fdf;
  border-bottom: 2px solid #009fdf;
  color: #fff;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
}
.table-fixed thead > tr > th,
.table-fixed thead > tr > td,
.table-fixed tbody > tr > th,
.table-fixed tbody > tr > td {
  white-space: nowrap;
}
.table-centered th,
.table-centered td {
  text-align: center;
}
.table-stacked thead {
  display: none;
}
.table-stacked tbody td {
  display: block;
  padding-bottom: 0;
}
.table-stacked tbody td::before {
  content: attr(data-heading) ":";
  color: #009fdf;
  display: block;
  float: left;
  padding-right: 14px;
  padding-bottom: 14px;
  width: 40%;
}
.table-stacked tbody td + td {
  border-top: 1px solid #ace7ff;
  clear: both;
  padding-bottom: 14px;
}
.table-striped > tbody > tr:nth-of-type(odd) a {
  color: #7fba00;
}
@media (min-width: 740px) {
  .table thead > tr > th,
  .table thead > tr > td,
  .table tbody > tr > th,
  .table tbody > tr > td {
    white-space: normal;
  }
  .table-stacked thead {
    display: table-header-group;
  }
  .table-stacked tbody td {
    display: table-cell;
    padding-bottom: 14px;
  }
  .table-stacked tbody td::before {
    content: none;
  }
  .table-stacked tbody td + td {
    border-top: 0;
  }
}
.scrollable-table {
  height: 400px;
  position: relative;
}
.scrollable-table .outer-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.scrollable-table .inner-container {
  height: 100%;
  overflow: hidden;
}
.scrollable-table .table-wrapper {
  margin: 0;
}
.scrollable-table .table-wrapper.table-header {
  margin-bottom: 0;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: visible;
}
.scrollable-table .table-wrapper.table-body {
  margin-top: -2px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.scrollable-table .table-wrapper.table-body #bodytable {
  max-width: none;
  height: 20px;
}
.scrollable-table .table-wrapper th,
.scrollable-table .table-wrapper td {
  width: 80px;
  min-width: 80px;
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-word;
}
.scrollable-table .table-wrapper .col-sm {
  min-width: 120px;
}
.scrollable-table .table-wrapper .col-md {
  min-width: 160px;
}
.scrollable-table .table-wrapper .col-lg {
  min-width: 200px;
}
.scrollable-table #headertable {
  position: relative;
}
.scrollable-table .header-cell {
  background-color: #009fdf;
}
.scrollable-table .table-body {
  overflow: auto;
}
.popup {
  margin-bottom: 30px;
}
.popup [class*="btn-tw-"] {
  margin-bottom: 0;
}
.popup-banner .fa {
  vertical-align: middle;
}
.popup-content {
  padding-top: 15px;
}
.popup-content h1:first-child,
.popup-content h2:first-child,
.popup-content h3:first-child,
.popup-content h4:first-child {
  margin-top: 10px;
}
.popup-content .meta {
  color: #71857e;
}
.popup-content p {
  padding-top: 0 !important;
}
.popup-content p a {
  color: #7fba00;
}
.popup-content p a:hover {
  text-decoration: underline;
}
@media (min-width: 740px) {
  border: 3px solid #dae6e2;
  border-radius: 6px;
  margin-bottom: 44px;
  [class*="btn-tw-"] {
    margin-bottom: 0;
    margin-top: 0;
  }
  .popup-banner {
    padding: 15px;
    background: #009fdf;
    border-radius: 6px;
    margin: -3px -3px -6px;
  }
  .popup-content {
    padding: 35px 33px;
  }
  .popup {
    border: 3px solid #dae6e2;
    border-radius: 6px;
    margin-bottom: 44px;
  }
  .popup [class*="btn-tw-"] {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.popup-fixed {
  border: 3px solid #dae6e2;
  border-radius: 6px;
  margin-bottom: 44px;
}
.popup-fixed [class*="btn-tw-"] {
  margin-bottom: 0;
  margin-top: 0;
}
.popup-fixed .popup-banner {
  padding: 15px;
  background: #009fdf;
  border-radius: 6px;
  margin: -3px -3px -6px;
}
.popup-fixed .popup-content {
  padding: 35px 33px;
}
.error404 {
  margin: 30px 0;
  padding: 0 25px;
  text-align: center;
}
.error404-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.error404-content {
  line-height: 1.57;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.error404-content a {
  color: #7fba00;
  white-space: nowrap;
}
.error404-title {
  margin-bottom: 16px;
}
.error404-image {
  height: 120px;
  margin: 0 auto 20px;
  width: 120px;
}
@media (min-width: 740px) {
  .error404 {
    margin: 40px 0;
    padding: 0 60px 0 40px;
    text-align: left;
  }
  .error404-inner {
    display: table;
    overflow: hidden;
    width: 100%;
  }
  .error404-image {
    float: right;
    height: 220px;
    margin-bottom: 0;
    margin-left: 20px;
    width: 220px;
  }
  .error404-content {
    display: table-cell;
    line-height: 1.63;
    vertical-align: middle;
  }
  .error404-title {
    font-size: 32px;
    vertical-align: middle;
  }
}
@media (min-width: 1020px) {
  .error404 {
    margin: 40px 0 60px;
    padding: 0 100px;
  }
  .error404-image {
    height: 320px;
    margin-left: 120px;
    width: 320px;
  }
}
@media (min-width: 1260px) {
  .error404 {
    margin: 40px 0 85px;
  }
  .error404-image {
    height: 380px;
    width: 380px;
  }
}
.sitemap-links {
  border: 2px solid #dae6e2;
  margin-bottom: 45px;
}
.sitemap-links .sitemap-title {
  border-bottom-color: #7fba00;
  line-height: 49px;
  min-height: 50px;
  margin: 0 15px;
  padding: 0;
}
.sitemap-links .sitemap-title h2 {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  margin: 0;
  vertical-align: middle;
}
.sitemap-links ul {
  line-height: 1.1;
  margin: 0 !important;
  padding: 0;
  list-style: none;
  text-indent: 0 !important;
}
.sitemap-links li {
  margin: 15px 0;
}
.sitemap-links li::before {
  content: none;
  display: none;
}
.sitemap-links li:first-child {
  margin-top: 5px;
}
.sitemap-links li:last-child {
  margin-bottom: 5px;
}
.sitemap-links a {
  color: #71857e;
  display: block;
  padding-left: 17px;
  position: relative;
}
.sitemap-links a::before {
  color: #7fba00;
  content: "";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
}
.gallery {
  border-bottom: 1px solid #dae6e2;
  margin-bottom: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.gallery.active [rel^="lightbox"] a,
.gallery.active [rel^="lightbox"] {
  cursor: -webkit-zoom-in;
}
.gallery-controls {
  border-bottom: 1px solid #dae6e2;
  padding-bottom: 19px;
  text-align: right;
}
.gallery-controls .checkbox,
.gallery-controls .radio {
  display: inline-block;
  font-weight: normal;
  margin-top: 0;
  margin-left: 30px;
}
.gallery-list {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 4px;
  list-style: none;
}
.gallery-list:before,
.gallery-list:after {
  content: " ";
  display: table;
}
.gallery-list:after {
  clear: both;
}
.gallery-item {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 18px;
}
@media (min-width: 740px) {
  .gallery-item {
    float: left;
    width: 33.33333%;
  }
}
.gallery-item a {
  border-radius: 4px;
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 71.42857%;
  position: relative;
}
.gallery-item a[href*="video"]::before,
.gallery-item a[href*="youtu"]::before,
.gallery-item a[href*="vimeo"]::before,
.gallery-item a[href*="buto"]::before {
  background: rgba(127, 186, 0, 0.8);
  border-radius: 50%;
  color: #fff;
  content: "\f04b";
  font-family: FontAwesome;
  font-size: 24px;
  height: 2em;
  left: 50%;
  line-height: 2;
  margin: auto;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 2em;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.gallery-item img {
  min-height: 100%;
  position: absolute;
  width: 100%;
}
.gallery-item span {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.gallery .pagination {
  margin-top: 0;
}
@media (min-width: 740px) {
  .gallery-item span {
    line-height: 1.8em;
    line-height: 1.2;
  }
  .gallery-item span else {
    font-size: 11px;
  }
}
.lightbox {
  opacity: 0;
  position: relative;
  visibility: hidden;
  z-index: -1;
  -webkit-transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.lightbox.lifted {
  z-index: 11;
}
.lightbox.active {
  opacity: 1;
  visibility: visible;
}
.lightbox button:hover,
.lightbox a:hover {
  color: #4d8504;
}
.lightbox-controls,
.lightbox-content {
  position: fixed;
}
.lightbox-controls {
  color: #fff;
  line-height: 1;
  top: 0;
  width: 100%;
  z-index: 1;
}
.lightbox-download,
.lightbox-close {
  float: right;
}
.lightbox-prev,
.lightbox-next,
.lightbox-index,
.lightbox-total,
.lightbox-download {
  display: inline-block;
  margin-top: 20px;
}
.lightbox-prev,
.lightbox-next,
.lightbox-download,
.lightbox-close {
  color: #7fba00;
  cursor: pointer;
  text-decoration: underline;
}
.lightbox-prev,
.lightbox-next {
  background: 0;
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: color 0.25s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: color 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: color 0.25s cubic-bezier(0, 0, 0.2, 1);
}
.lightbox-prev.disabled,
.lightbox-next.disabled {
  color: darker(#4d8504, 10%);
  cursor: default;
  text-decoration: none;
}
.lightbox-prev {
  margin-left: 18px;
}
.lightbox-index {
  margin-left: 18px;
  text-align: right;
  width: 26px;
}
.lightbox-index::after {
  content: "/";
  margin: 0 0.2em;
}
.lightbox-total {
  margin-right: 18px;
}
.lightbox-download {
  font-size: 16px;
  margin-top: 19px;
  margin-right: 18px;
}
.lightbox-download.disabled {
  display: none;
}
.lightbox-close {
  background: #fff;
  border: 0;
  border-radius: 50%;
  color: #000;
  font-size: 23px;
  height: 32px;
  line-height: 32px;
  margin-top: 12px;
  margin-right: 8px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 32px;
}
.lightbox-content {
  height: 100%;
  top: 0;
  width: 100%;
}
.lightbox-cell {
  height: 100%;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.lightbox figure {
  height: 100%;
  padding-top: 44px;
  position: relative;
}
.lightbox figure > img {
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  padding: 70px 8%;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.lightbox figcaption {
  background: rgba(0, 0, 0, 0.67);
  bottom: 0;
  color: #fff;
  position: absolute;
  width: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.lightbox figcaption span {
  overflow: hidden;
  padding: 10px 10px 20px;
  text-overflow: ellipsis;
  width: 74%;
  white-space: nowrap;
}
.lightbox figcaption button {
  background: 0;
  border: 0;
  color: #7fba00;
  cursor: pointer;
  margin: auto 10px 20px auto;
  padding: 0 10px;
  text-decoration: underline;
}
.lightbox figcaption.open {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.lightbox figcaption.open span {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1;
  width: 100%;
  white-space: normal;
}
.lightbox figcaption.open button {
  text-outline: 0;
  margin-bottom: 0;
  margin-left: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -moz-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.lightbox .embed-responsive {
  height: 84% !important;
  margin: 4% 8%;
  padding: 0 !important;
  width: 84% !important;
}
.lightbox .embed-responsive > iframe {
  position: static;
}
@media (min-width: 740px) {
  .lightbox figcaption {
    display: block;
  }
  .lightbox figcaption button,
  .lightbox figcaption span {
    display: inline-block;
    vertical-align: middle;
  }
  .lightbox figcaption span {
    padding: 20px 0 20px 20px;
    width: 84%;
  }
  .lightbox figcaption button {
    margin: 10px auto;
    padding: 10px 0;
    width: 16%;
  }
  .lightbox figcaption.open span {
    padding: 20px;
    width: 84%;
  }
  .lightbox figcaption.open button {
    margin: 0;
  }
}
.incidents {
  margin: 0 0 10px;
}
.incidents.lifted {
  z-index: 11;
}
.incidents .alert {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 12px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 5px 70px 5px 10px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.incidents .alert::before {
  background-color: #fff;
  left: 10px;
  top: 5px;
}
.incidents .alert:focus {
  box-shadow: none;
  border-color: #009fdf;
}
.incidents .alert.alert-success,
.incidents .alert.alert-info,
.incidents .alert.alert-warning,
.incidents .alert.alert-danger {
  padding-left: 41px;
}
.incidents .alert.alert-success {
  background-color: #7fba00;
}
.incidents .alert.alert-success::before {
  color: #7fba00;
  content: "";
}
.incidents .alert.alert-info {
  background-color: #009fdf;
}
.incidents .alert.alert-info::before {
  color: #009fdf;
  content: "";
}
.incidents .alert.alert-warning {
  background-color: #e8b600;
}
.incidents .alert.alert-warning::before {
  color: #e8b600;
  content: "";
}
.incidents .alert.alert-danger {
  background-color: $red;
}
.incidents .alert.alert-danger::before {
  color: $red;
  content: "";
}
.incidents .alert em {
  font-style: normal;
  text-decoration: underline;
}
.incidents button {
  cursor: pointer;
}
.incidents .counter,
.incidents .prev-next-button,
.incidents .play-pause-button {
  background: 0;
  border: 0;
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  -webkit-transition: color 250ms cubic-bezier(0, 0, 0.2, 1), border-color 250ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: color 250ms cubic-bezier(0, 0, 0.2, 1), border-color 250ms cubic-bezier(0, 0, 0.2, 1);
  transition: color 250ms cubic-bezier(0, 0, 0.2, 1), border-color 250ms cubic-bezier(0, 0, 0.2, 1);
}
.incidents .incidents-controls {
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.incidents .incidents-controls .counter,
.incidents .incidents-controls .prev-next-button,
.incidents .incidents-controls .play-pause-button {
  color: #fff;
  height: 32px;
  line-height: 32px;
}
.incidents .incidents-controls .counter {
  text-align: right;
}
.incidents .incidents-controls .play-pause-button[disabled] {
  color: #fff;
  color: rgba(255, 255, 255, 0.25);
  cursor: default;
  text-decoration: none;
}
.incidents .incidents-controls .prev-next-button {
  display: none;
  text-decoration: underline;
}
.incidents .incidents-controls .play-pause-button {
  border: 2px solid;
  border-color: currentColor;
  border-radius: 50%;
  height: 26px;
  font-size: 12px;
  line-height: 22px;
  margin: 0 5px 0 10px;
  width: 26px;
}
.incidents .incident {
  width: 100%;
  -webkit-transition: height 250ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: height 250ms cubic-bezier(0, 0, 0.2, 1);
  transition: height 250ms cubic-bezier(0, 0, 0.2, 1);
}
.incidents .incident-content {
  padding: 14px;
}
.incidents .incident-controls {
  margin-bottom: 20px;
}
.incidents .incident-controls .prev-next-button {
  color: #7fba00;
  margin-right: 30px;
  text-decoration: underline;
}
.incidents .incident header {
  border-bottom: 1px solid #dae6e2;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.incidents .incident h1,
.incidents .incident h2 {
  display: block;
}
.incidents .incident h1 {
  color: $red;
}
.incidents .incident h2 {
  color: #000;
}
.incidents .incident span {
  display: block;
  line-height: 1.6;
}
.incidents .incident figure {
  margin: 15px 0;
}
.incidents .incident figure img {
  border-radius: 5px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.incidents .incident figure figcaption {
  color: #009fdf;
  display: block;
  margin: 10px 0;
  font-style: italic;
}
.incidents .incident footer {
  margin-top: 20px;
}
.incidents.flickity-enabled .flickity-viewport {
  z-index: 1;
  -webkit-transition: height 150ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: height 150ms cubic-bezier(0, 0, 0.2, 1);
  transition: height 150ms cubic-bezier(0, 0, 0.2, 1);
}
.incidents.flickity-enabled .incident-container {
  position: relative;
  top: -5px;
}
.incidents.flickity-enabled .incident {
  overflow: hidden;
  position: absolute;
  top: 0;
}
.incidents.flickity-enabled .incident-content {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  bottom: 0;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  position: absolute;
}
.js .incidents {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.js .incidents.flickity-enabled {
  height: auto;
  overflow: visible;
  visibility: visible;
}
.incidents.is-open {
  z-index: 3;
}
.incidents.is-open .incident {
  z-index: 1;
}
.incidents.is-open .flickity-viewport {
  z-index: 2;
}
.incidents.is-open .incidents-controls {
  z-index: 3;
}
@media (min-width: 740px) {
  .incidents .alert {
    border: 0;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 170px 10px 10px;
  }
  .incidents .alert::before {
    left: 14px;
    top: 12px;
  }
  .incidents .alert span:first-child::before {
    content: "Network latest: ";
    font-size: 16px;
    font-weight: 700;
  }
  .incidents .alert strong {
    display: inline;
  }
  .incidents .alert strong::after {
    content: ": ";
  }
  .incidents .alert.alert-success,
  .incidents .alert.alert-info,
  .incidents .alert.alert-warning,
  .incidents .alert.alert-danger {
    padding-left: 44px;
  }
  .incidents .incidents-controls {
    font-size: 14px;
  }
  .incidents .incidents-controls .counter,
  .incidents .incidents-controls .prev-next-button {
    height: 45px;
    line-height: 45px;
    margin: 2px 3px;
  }
  .incidents .incidents-controls .prev-next-button {
    display: inline-block;
  }
  .incidents .incidents-controls .play-pause-button {
    margin-top: 2px;
    margin-right: 10px;
  }
  .incidents .incident-content {
    padding: 25px 30px 40px;
  }
  .incidents .incident-controls {
    display: none;
  }
  .incidents .incident header {
    padding-bottom: 20px;
  }
  .incidents .incident figure {
    margin-top: 0;
  }
  .incidents .incident .btn-tw-primary,
  .incidents .incident .btn-tw-secondary {
    margin: 0;
  }
  .incidents .incident .btn-tw-secondary {
    float: right;
  }
  .incidents.flickity-enabled .incident-content {
    border-width: 2px;
  }
}
@media (min-width: 1020px) {
  .incidents .incident figure {
    margin-top: 20px;
  }
}
.map {
  background-color: #d9f1fa;
  border-radius: 5px;
  min-height: 220px;
  min-width: 250px;
  position: relative;
  width: 100%;
}
.map > div {
  border-radius: 5px;
  overflow: hidden;
}
.map {
  background-color: #d9f1fa;
  border-radius: 5px;
  height: 545px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.map > div {
  border-radius: 5px;
  overflow: hidden;
}
.incidents .map {
  height: 175px;
  width: 100%;
}
@media (min-width: 1020px) {
  .incidents .map {
    width: 250px;
    max-width: 100%;
  }
}
.map-overlay {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 545px;
  left: 10px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 2;
}
.map-overlay > div {
  margin: 40px;
}
@media (min-width: 740px) {
  .map-overlay > div {
    margin: 60px;
  }
}
@media (min-width: 1020px) {
  .map-overlay > div {
    margin: 100px;
  }
}
.map-overlay:empty {
  z-index: 0;
}
.map-overlay .preloader {
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.location {
  font-size: 14px;
  line-height: 1;
  min-width: 320px;
  width: auto !important;
}
.location .infoBoxMain {
  bottom: 0;
  left: 0;
  position: absolute;
}
.location .location-inner {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px 20px 20px;
  position: relative;
  width: 100%;
}
.location .location-inner::after {
  background: #fff;
  bottom: -9px;
  box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.2);
  content: "";
  height: 17.80449px;
  left: 21px;
  position: absolute;
  width: 17.80449px;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  -ms-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}
.location .infoBoxFooter {
  height: 14px;
}
.location h2,
.location h3,
.location h4,
.location p {
  margin-bottom: 12px;
  margin-top: 0;
  white-space: nowrap;
}
.location h2:last-child,
.location h3:last-child,
.location h4:last-child,
.location p:last-child {
  margin-bottom: 0;
}
.pager {
  padding-left: 0;
  margin: 15px 0;
  list-style: none;
  text-align: center;
}
.pager:before,
.pager:after {
  content: " ";
  display: table;
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.map-key-container {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  float: right;
  position: relative;
  height: 0;
  top: -236px;
  width: 300px;
}
@media (max-width: 739px) {
  .map-key-container {
    margin-bottom: 20px;
    width: 100%;
  }
}
.map-key-container.open {
  height: 236px;
  position: relative;
  top: 0;
}
.map-key-container.open .map-key-button span:after {
  content: "";
}
.map-key-container ul {
  background-color: #e9f4f0;
  border: 2px solid #71847e;
  border-top: 0;
  border-bottom-left-radius: 5px;
  margin: 0;
  padding: 10px;
}
.map-key-container ul li {
  margin-bottom: 10px;
}
.map-key-container ul li:before {
  background-repeat: no-repeat;
  content: " ";
  display: inline-block;
  float: left;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}
.map-key-container ul li.multiple:before {
  background-image: url("/assets/images/pin-multiple.svg");
}
.map-key-container ul li.repairs:before {
  background-image: url("/assets/images/pin-repairs.svg");
}
.map-key-container ul li.reported-leaks:before {
  background-image: url("/assets/images/pin-reported-leaks.svg");
}
.map-key-container ul li.planned-improvements:before {
  background-image: url("/assets/images/pin-planned-improvements.svg");
}
.map-key-container ul li.maintenance:before {
  background-image: url("/assets/images/pin-maintenance.svg");
}
.map-key-container ul li.traffic-disruptions:before {
  background-image: url("/assets/images/pin-traffic-disruptions.svg");
}
.map-key-container ul li:last-child {
  margin-bottom: 0;
}
.map-key-container .map-key-button {
  cursor: pointer;
  display: inline-block;
  float: right;
  position: relative;
  z-index: 1;
}
.map-key-container .map-key-button span {
  background-color: #71847e;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  display: inline-block;
  font-family: "Gotham Rounded", Arial, "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 600;
  padding: 10px 16px;
  min-width: 110px;
}
.map-key-container .map-key-button span:after {
  content: "";
  font-family: "FontAwesome";
  float: right;
}
[data-journey] {
  height: 1px;
  overflow: hidden;
  visibility: hidden;
}
[data-journey][data-journey*=","],
[data-journey].open {
  height: auto;
  visibility: visible;
}
.message-strip {
  border-width: 2px 0 0;
  border-radius: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1;
}
.message-strip::before {
  content: none;
}
.message-strip-inner {
  padding: 48px 13px 21px 13px;
}
.message-strip-inner::before {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #009fdf;
  border-radius: 50%;
  color: #fff;
  content: "";
  height: 20px;
  left: 50%;
  line-height: 1.143;
  padding: 2px;
  position: absolute;
  text-align: center;
  top: 25px;
  width: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-class-wscontactus .page-header {
  text-align: center;
}
.message-strip .button-container *:last-child {
  margin-bottom: 0;
}
.message-strip .btn {
  display: block;
  font-size: 80%;
  font-size: 1em;
  line-height: 1;
  margin: 15px 0;
  padding: 12px 18px;
  width: 100%;
}
@media (min-width: 740px) {
  .message-strip-inner {
    margin: 0 auto;
    padding: 21px 13px;
    width: 700px;
  }
  .message-strip-inner::before {
    margin-right: 11px;
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .message-strip-inner::before,
  .message-strip .message,
  .message-strip .button-container {
    display: inline-block;
    vertical-align: middle;
  }
  .message-strip .message {
    width: 340px;
  }
  .message-strip .button-container {
    margin-left: 13px;
    width: 282px;
  }
  .message-strip .btn {
    display: inline-block;
    line-height: 22px;
    margin: 0;
    padding: 10px 16px;
    width: auto;
  }
  .message-strip .dismiss {
    margin-right: 5px;
  }
}
@media (min-width: 1020px) {
  .message-strip-inner {
    width: 940px;
  }
  .message-strip-inner::before {
    margin-right: 15px;
  }
  .message-strip .message {
    width: 576px;
  }
}
@media (min-width: 1260px) {
  .message-strip-inner {
    width: 1180px;
  }
  .message-strip .message {
    width: 816px;
  }
}
.yui-skin-sam .mask {
  display: none !important;
}
